import { config } from '@fortawesome/fontawesome-svg-core';

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const appConfig = (function () {

    const defaultConfig = {
        timezone: "Europe/Lisbon",
        language: "pt_PT",
        api: {
            //base: "https://staging.siac.vet/api/",
            base: "https://portal.siac.vet/api/",
            queryParamStringifyOptions: {
                strictNullHandling: true,
                skipNulls: true,
                arrayFormat: 'comma',
            }
        }
    };

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return {
            ...defaultConfig,
            api: {
                ...defaultConfig.api,
            }
        }
    } else {

        return {
            ...defaultConfig,
            api: {
                ...defaultConfig.api,
            }
        }
    }


})
();


export default appConfig;
