import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {useCallback} from "react";

import "../assets/css/hint.scss";
import InputTextComponent from "./InputText";
import classnames from "classnames";

const TextComponent = (props, ref) => {

    const {currentValue, className, type: defaultType, disabled, onChange, onClear} = props;
    const [type, setType] = React.useState(defaultType);

    const togglePasswordVisibility = useCallback(
        () => setType((type) => type === "password" ? "text" : "password"),
        [setType]
    );


    return <div className={classnames("input-wrapper ",className)}>
        <InputTextComponent {...props} type={type} ref={ref} onChange={onChange}/>
        <div className="form-control-extra-buttons">
            {!disabled &&
            <>
                <i className="eye" onClick={togglePasswordVisibility}
                   style={{visibility: defaultType === "password" && currentValue ? 'visible' : 'hidden'}}>
                    <FontAwesomeIcon icon={type === "password" ? faEye : faEyeSlash}/>
                </i>

                <i>
                    <FontAwesomeIcon icon={faTimes} onClick={onClear}
                                     style={{visibility: currentValue ? 'visible' : 'hidden'}}/>
                </i>
            </>
            }
        </div>
    </div>

};

const FormControlTextComponent = React.forwardRef(TextComponent);

export default FormControlTextComponent;
