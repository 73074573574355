export const pt = {


    how_works_title: "Como funciona",
    how_works_sub_title: "O que deve ser feito quando perde o seu animal ou encontra um animal",


};

export default pt;
