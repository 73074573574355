import Box from "modules/Box/Box";
import BoxTitle from "modules/Box/components/BoxTitle";
import {Form, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import FormGroup from "modules/Form/Group/FormGroup";
import FormControl from "modules/Form/Control/FormControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import {useCallback} from "react";
import PhoneControl from "modules/Form/PhoneControl/PhoneControl";
import {useTranslation} from "react-i18next";

const FormParticipantDataComponent = props => {

    const {errors = {}, lostInfo, setLostInfo, readOnly} = props;
    const {t} = useTranslation("animal");

    const handleEmail = useCallback(e => setLostInfo(lostInfo => ({
        ...lostInfo,
        participantEmail: e.target.value?.toLowerCase(),
    })), [setLostInfo]);


    return <Box>
        <BoxTitle>{t('participant_data')}</BoxTitle>

        <Row>
            <Col sm={12} md={12} lg={12}>
                <FormGroup error={errors['participantName']} mandatory={true}>
                    <Form.Label>{t('participant_name')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('participant_name_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.participantName)}
                        onChange={useCallback((e) => {
                            setLostInfo((info) => ({
                                ...info,
                                participantName: e.target.value,
                            }))
                        }, [setLostInfo])}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantName'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantContact']} mandatory={true}>
                    <Form.Label>{t('participant_contact')}</Form.Label>
                    <PhoneControl
                        value={getOrEmpty(lostInfo?.participantContact)}
                        disabled={readOnly}
                        onChange={useCallback(phone => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            participantContact: phone,
                        })), [setLostInfo])}
                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantContact'])}
                    </Form.Text>
                </FormGroup>
            </Col>

            {!readOnly &&
            <Col sm={12} md={6} lg={6}>
                <FormGroup error={errors['participantEmail']} mandatory={true}>
                    <Form.Label>{t('participant_email')}</Form.Label>
                    <FormControl
                        type="text"
                        placeholder={t('email_ph')}
                        disabled={readOnly}
                        value={getOrEmpty(lostInfo?.participantEmail)}
                        onChange={handleEmail}

                    />
                    <Form.Text className="sub-error">
                        {t(errors['participantEmail'])}
                    </Form.Text>
                </FormGroup>
            </Col>
            }

        </Row>

    </Box>


}

export default FormParticipantDataComponent;
