import {useCallback, useState} from "react";
import useErrorParser from "system/API/Error/ErrorParser";
import {useMutate} from "system/Rest/Rest";
import FormPageComponent from "app/AnimalLost/components/form/FormPageComponent";

const Form = (props) => {

    const [success, setSuccess] = useState(false);

    const {mutate: submit, loading, error} = useMutate({
        verb: "POST",
        path: "lost-animals/pending",
    });

    const errors = useErrorParser(error);

    return <FormPageComponent
        {...props}
        errors={errors}
        genericError={error?.data?.message}
        loading={loading}
        success={success}
        onSubmit={useCallback((lostInfo) => {
            submit(lostInfo).then(() => {
                setSuccess(true);
            });
        }, [setSuccess, submit])}
    />
}

export default Form;
