import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classnames from "classnames";
import {GenderType} from "app/AnimalLost/utils/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMars, faVenus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useTranslation} from "react-i18next";
import "app/AnimalLost/assets/animal-resume.scss";

const AnimalResumeComponent = props => {

    const {t} = useTranslation('animal');
    const {animal} = props;

    return <div className={"animal-resume"}>
        <Row>
            <Col sm={10} md={10} xl={10}>
                <div className={"title"}>
                    {t('breed')}
                </div>
                <div className={"value"}>
                    {animal.breed ? animal.breed : "-"}
                    {animal.crossBreed &&
                    <>&nbsp;X&nbsp;{animal.crossBreed}</>
                    }
                </div>

            </Col>

            <Col xs={2} md={2} xl={2}>
                <div className={classnames("gender-icon", animal?.gender?.toLowerCase())}>
                    {animal?.gender === GenderType.FEMALE
                        ? <FontAwesomeIcon icon={faVenus}/>
                        : <FontAwesomeIcon icon={faMars}/>
                    }
                </div>
            </Col>
        </Row>


        <Row>
            <Col xs={12} md={12} xl={12}>
                <div className={"title"}>
                    {t('place')}
                </div>
                <div className={"value"}>
                    {animal?.eventCounty?.name} - {animal?.eventParish?.name}
                </div>

            </Col>
        </Row>
    </div>

}
export default AnimalResumeComponent;
