import {useTranslation} from "react-i18next";
import React from "react";
import TableHeader from "modules/TableAdvanced/components/header/TableHeader";
import SearchArea from "modules/SearchArea/SearchArea";
import SearchFilter from "modules/SearchArea/components/SearchFilter";
import ListFilterComponent from "app/AnimalLost/components/list/ListFilterComponent";
import "app/AnimalLost/assets/list.scss";
import "app/AnimalLost/assets/card-list.scss";
import "app/AnimalLost/assets/card.scss";
import InfiniteListContainer from "modules/GenericTableContainer/InfiniteListContainer";
import ListEntriesComponent from "app/AnimalLost/components/list/ListEntriesComponent";
import TopBanner from "layout/components/TopBanner/TopBanner";
import Loading from "modules/Loading/Loading";


const ListComponent = props => {

    const {t} = useTranslation('animal');
    const {data, onSearch, searchFilter, onFilterSubmit, loading} = props;

    return <>

        <TopBanner>
            <div className={"main-text"}>
                {t('banner_main_msg')}
            </div>

            <div className={"sec-text"}>
                {t('banner_sec_msg')}
            </div>
        </TopBanner>

        <div className={"lost-list-page"}>

            <div className={"lost-list-title"}>
                {t('lost_list_title')}
                <div className={"title-details"}>
                    {t('lost_list_title_msg')}
                </div>
            </div>

            <div className={"table-content"}>
                <div className={"lost-list-header"}>
                    <TableHeader>
                        <SearchArea onSearch={onSearch} value={searchFilter.universalSearch}>
                            <SearchFilter onSubmit={(data) => onFilterSubmit(data)}>
                                <ListFilterComponent searchFilter={searchFilter} t={t}/>
                            </SearchFilter>
                        </SearchArea>
                    </TableHeader>

                </div>


                <div className={"card-list-container"} id="card-list-container">
                    <Loading visible={loading} localOnly={true}/>
                    <InfiniteListContainer {...props} manual={true}>
                        <ListEntriesComponent {...props}/>
                    </InfiniteListContainer>

                    {data?.content?.length === 0 &&
                    <div className={"no-table-results"}>
                        {t('no_results')}
                    </div>
                    }

                </div>


            </div>
        </div>
    </>
}

export default ListComponent;
