import {Trans, useTranslation} from "react-i18next";
import ExternalAnimalCardComponent from "app/AnimalLost/components/list/ExternalAnimalCardComponent";
import React from "react";
import {Link} from "react-router-dom";

const ListEntriesComponent = props => {

    const {data} = props;
    const {t} = useTranslation('animal');

    return <div className={"card-list"}>
        {data?.content?.map((value, index) => {
            if (value?.exists === true) {
                return <div className={"exists-not-public"}>
                    <Trans>{t('animal_lost_not_public')}</Trans>
                </div>
            } else {
                return <Link className={"card-container"} key={index} to={'/' + value?.id} state={{animal: value}}>
                    <ExternalAnimalCardComponent {...props} animal={value}/>
                </Link>
            }
        })}
    </div>;
}

export default ListEntriesComponent;
