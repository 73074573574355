import dayjs from "dayjs"

export function generateInitialDate(date, children) {
    if (children) {
        return dayjs(children)
    } else if (date) {
        return dayjs(date)
    }
    return dayjs(0);
}

export function convertTimezone(date, children, timeZone) {

    let utc = require('dayjs/plugin/utc') // dependent on utc plugin
    let timezone = require('dayjs/plugin/timezone');

    dayjs.extend(utc)
    dayjs.extend(timezone);

    if (children) {
        return dayjs(children).tz(timeZone);
    } else if (date) {
        return dayjs(date).tz(timeZone);
    }
    return dayjs()


}

export function addToDate(date, add) {
    return Object.keys(add).reduce((updatedDate, key) => updatedDate.add(add[key], key), date)
}

export function subtractFromDate(date, subtract) {
    return Object.keys(subtract).reduce((updatedDate, key) => updatedDate.subtract(subtract[key], key), date)
}

export function convertSecondsToTime(seconds) {

    if (!isNaN(seconds)) {
        return new Date(seconds * 1000).toISOString().substr(11, 8);
    }
    else {
        return "";
    }
}

const dateFormat = "DD-MM-YYYY";

export function validateDateString(dateString) {

    let customParseFormat = require('dayjs/plugin/customParseFormat')
    let utc = require('dayjs/plugin/utc') // dependent on utc plugin
    let timezone = require('dayjs/plugin/timezone');

    dayjs.extend(utc)
    dayjs.extend(customParseFormat);
    dayjs.extend(timezone);

    try {
        if (dayjs(dateString, dateFormat).format(dateFormat) === dateString) {
            return dayjs(dateString, dateFormat).tz("UTC").toDate()
        }
        return false;
    } catch {
        return false;
    }
}


export function getDateInStringFormat(date) {
    if (date instanceof Date) {
        return dayjs(date).format(dateFormat);
    }
    else {
        return date;
    }
}
