
import {getOrEmpty} from "../../../../system/Objects/ObjectParameters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import React, {useCallback, useRef} from "react";
import FormControlWithEmptyValue from "../../Control/components/FormControlWithEmptyValue";
import InputTextComponent from "../../Control/components/InputText";


const TransponderFormComponent = React.forwardRef((props, ref) => {

    const {value, onChange, disabled} = props;

    const localRef = useRef(null);
    const inputTextRef = ref ? ref : localRef;

    const handleClear = useCallback(
        () => {
            inputTextRef.current.value = '';
            onChange('');
        },
        [onChange, inputTextRef]
    );

    const handleChange = useCallback(
        (e) => onChange(e.target.value.trim()),
        [onChange]
    );

    if (disabled) {
        return <FormControlWithEmptyValue
            {...props}
            disabled={true}
            value={getOrEmpty(value)}/>
    } else {
        return <>
            <InputTextComponent
                {...props}
                value={getOrEmpty(value)}
                onChange={handleChange}
                ref={inputTextRef}
                type={"text"}

            />
            <div className="form-control-extra-buttons">
                <i className={"transponder-control-counter"}>
                    {value?.length ? value.length : 0} | 15
                </i>
                <i>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={classnames({'d-none': !value})}
                        onClick={handleClear}
                    />
                </i>
            </div>
        </>
    }
});

export default TransponderFormComponent;
