import ViewAnimalComponent from "app/AnimalLost/components/view/ViewAnimalComponent";
import {useLocation, useParams} from "react-router-dom";
import {useMemo} from "react";
import {useGet} from "system/Rest/Rest";

const AnimalLost = props => {

    const {id} = useParams();
    const location = useLocation();

    const stateAnimal = location?.state?.animal;

    const {data: animal, loading} = useGet({
        path: "/lost-animals/",
        queryParams: useMemo(() => ({
            id: [id],
            itemsPerPage: 1
        }), [id]),
        lazy: !!stateAnimal,
        resolve: (data) => data.content[0]
    });

    return <ViewAnimalComponent {...props} animal={stateAnimal ? stateAnimal : animal} loading={loading} />
}

export default AnimalLost;
