import {useCallback} from "react";

const useScrollToError = () => {

    return useCallback(
        () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        []
    );

}

export default useScrollToError;
