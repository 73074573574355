export const pt = {


    role_ph: "Selecione um Perfil",
    role_no: "Nenhum Perfil Encontrado",
    role_loading: "A Procurar Perfis..",

    entity_ph: "Selecione uma Entidade",
    entity_loading: "A Procurar Entidades..",
    entity_no: "Nenhuma Entidade Encontrada",

    entity_profile_ph: "Selecione um Perfil de Entidade",
    entity_profile_loading: "A procurar perfis de entidade...",
    entity_profile_no: "Nenhum Perfil encontrado",

    entity_vat_no: "Sem Resultados. Por favor introduza o NIF completo",
    entity_vat_loading: "A procurar entidade pelo NIF...",
    entity_vat_ph: "Por favor introduza o NIF da entidade",

    generic_no: "Nenhum resultado encontrado",
    generic_loading: "A Procurar..",
    generic_ph: "Selecione uma opção",

    veterinary_ph: "Selecione um Médico Veterinário",
    veterinary_loading: "A Procurar Médico Veterinário...",
    veterinary_no: "Nenhum resultado encontrado",

};

export default pt;
