import TopBanner from "layout/components/TopBanner/TopBanner";
import GoBack from "layout/components/TopBanner/GoBack";
import React from "react";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/AnimalLost/locales/locales";
import FormComponent from "app/AnimalLost/components/form/FormComponent";
import "app/AnimalLost/assets/form-page.scss";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import classnames from "classnames";

const FormPageComponent = props => {

    const {t} = useTranslations("animal", locale);
    const {type} = props;

    return <>

        <TopBanner className={"cat"}>
            <GoBack/>

            <div className={"main-text"}>
                {t(type === AnimalStatus.LOST ? 'lost_header' : 'found_header')}
            </div>

            <div className={"sec-text"}>
                {t(type === AnimalStatus.LOST ? 'lost_header_msg' : 'found_header_msg')}

            </div>

        </TopBanner>

        <div className={classnames("form-page",{"lost": type === AnimalStatus.LOST})}>
            <FormComponent {...props} />
        </div>
    </>
}

export default FormPageComponent
