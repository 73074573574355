
import {useCallback} from "react";
import DayJS from "../../modules/DayJS/DayJS";
import appConfig from "../../configs/config";
import {convertTimezone} from "../../modules/DayJS/functions";

const DateTime = (props) => {
    return <DayJS {...props} timeZone={appConfig.timezone}/>
}

const useDateTime = (format = "DD-MM-YYYY HH:mm:ss") => {

    return useCallback((timestamp, timezone) => {
        return convertTimezone(timestamp, null, timezone ? timezone : appConfig.timezone).format(format);
    }, [format]);
}

const useConvertedDate = () => {

    return useCallback((timestamp, timeZone) => {
        return convertTimezone(timestamp, null, timeZone ? timeZone : appConfig.timezone);
    }, []);
}


export default DateTime;

export {
    useDateTime,
    useConvertedDate,
}
