import cloneWithoutKeys from "system/Objects/Objects";
import isFunction from "system/Utils/isFunction";

const buildURL = (props) => {

    const {base, path, queryParams, pathParams} = props
    const p = isFunction(path) ? path({...pathParams}) : path;
    const url = new URL((base + p).replace(/([^:]\/)\/+/g, "$1"))

    if (queryParams) {
        url.search = new URLSearchParams(removeEmptyParameters(queryParams)).toString();
    }
    return url;

}

const removeEmptyParameters = (params) => {

    const emptyKeys = [];

    Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null || value === '') {
            emptyKeys.push(key);
        }
    });

    return cloneWithoutKeys(params, emptyKeys);

}

export {buildURL};
