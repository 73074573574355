import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useState} from "react";
import {faCheck, faStop} from "@fortawesome/free-solid-svg-icons";

import "./checkbox.scss";
import classnames from "classnames";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";

const CheckBox = (props) => {

    const {
        id,
        checked = false,
        disabled = false,
        label = "",
        square = false,
        radio = false,
        onChange = (v) => void 0
    } = props;

    const [value, setValue] = useState(checked);

    const handleChange = useCallback((e) => {
        if (!disabled) {
            onChange(!value);
            setValue(!value);
        }
    }, [value, disabled, onChange]);


    useEffect(() => {
        setValue(checked);
    }, [checked, setValue]);


    return <div className={classnames("checkbox", {"with-label": label, "radio": radio})} onClick={handleChange}>
        <input id={id} name={id} type="checkbox" checked={value} onChange={handleChange} disabled={disabled}/>
        <div className="checkbox-box">

            {radio
                ? <FontAwesomeIcon className={"checkbox-box-icon"} icon={faCircle}/>
                : <FontAwesomeIcon className={classnames("checkbox-box-icon", {"square": square})}
                                   icon={square ? faStop : faCheck}/>

            }

        </div>
        <div className="checkbox-label" style={{display: (label ? 'initial' : 'none')}}>
            {label}
        </div>
    </div>

}

export default CheckBox;
