import {Form, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import FormNotRegisteredComponent from "./FormNotRegisteredComponent";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import GeofenceForm from "modules/Form/Geofence/GeofenceForm";
import Box from "modules/Box/Box";
import BoxTitle from "modules/Box/components/BoxTitle";
import FormGroup from "modules/Form/Group/FormGroup";
import TransponderControl from "modules/Form/Transponder/TransponderControl";
import {getOrDefault, getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import {AnimalFoundStatus, AnimalStatus} from "app/AnimalLost/utils/types";
import EnumDropdown from "modules/Form/Dropdowns/EnumDropdown/EnumDropdown";
import DatePicker from "modules/Form/DatePicker/DatePicker";
import CountryDropdown from "modules/Form/Dropdowns/CountryDropdown/CountryDropdown";
import FormControl from "modules/Form/Control/FormControl";
import CheckBox from "modules/Form/CheckBox/CheckBox";
import FormParticipantDataComponent from "app/AnimalLost/components/form/FormParticipantDataComponent";
import PhotoComponent from "app/AnimalLost/components/form/PhotoComponent";

const FormDataComponent = props => {

    const {errors = {}, lostInfo, setLostInfo, readOnly} = props;
    const {t} = useTranslation("animal");

    const handleAllowAdvertising = useCallback((val) => setLostInfo(lostInfo => ({
        ...lostInfo,
        allowsAdvertising: val,
    })), [setLostInfo]);


    const handleObservations = useCallback((e) => setLostInfo(lostInfo => ({
        ...lostInfo,
        observations: e.target.value,
    })), [setLostInfo]);


    const eventGeoArea = useMemo(
        () => ({
            districts: getOrDefault(lostInfo?.eventDistrict?.id, lostInfo?.eventDistrict),
            counties: getOrDefault(lostInfo?.eventCounty?.id, lostInfo?.eventCounty),
            parishes: getOrDefault(lostInfo?.eventParish?.id, lostInfo?.eventParish),
        }),
        [lostInfo?.eventDistrict, lostInfo?.eventCounty, lostInfo?.eventParish]
    );

    return <>
        <Box>
            <BoxTitle>{t('general')}</BoxTitle>
            <Row>

                {!readOnly &&
                <Col sm={12} md={12} lg={12}>
                    <FormGroup error={errors['transponder']} mandatory={true}>
                        <Form.Label>{t('transponder')}</Form.Label>
                        <TransponderControl
                            id="lostTransponder"
                            placeholder={t('transponder_ph')}
                            disabled={readOnly}
                            value={getOrEmpty(lostInfo?.transponder)}
                            onInvalid={transponder => setLostInfo(lostInfo => ({
                                ...lostInfo,
                                transponder: transponder,
                            }))}
                            onValid={transponder => setLostInfo(lostInfo => ({
                                ...lostInfo,
                                transponder: transponder,
                            }))}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['transponder'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
                }

                <FormNotRegisteredComponent
                    t={t}
                    errors={errors}
                    lostInfo={lostInfo}
                    setLostInfo={setLostInfo}
                    readOnly={readOnly}
                />


                {lostInfo?.status === AnimalStatus.FOUND &&
                <Col sm={12} md={6} lg={6}>
                    <FormGroup error={errors['foundAnimalSituation']} mandatory={true}>
                        <Form.Label>{t('lost_animal_situation')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            isMulti={false}
                            placeholder={t('lost_animal_situation_ph')}
                            options={AnimalFoundStatus}
                            isDisabled={readOnly}
                            value={getOrNull(lostInfo?.foundAnimalSituation)}
                            onChange={status => setLostInfo(lostInfo => ({
                                ...lostInfo,
                                foundAnimalSituation: status,
                            }))}
                        />
                        <Form.Text className="sub-error">
                            {t(errors['foundAnimalSituation'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
                }


                <Col sm={12} md={6} lg={6}>
                    <FormGroup error={errors['eventDate']} mandatory={true}>
                        <Form.Label>{t('occurrence_date')}</Form.Label>
                        <DatePicker
                            name={"eventDate"}
                            placeholder={t("date_ph")}
                            onlyPastDates={true}
                            timezone={'UTC'}
                            disabled={readOnly}
                            value={getOrEmpty(lostInfo?.eventDate)}
                            onChange={useCallback(date => setLostInfo(lostInfo => ({
                                ...lostInfo,
                                eventDate: date,
                            })), [setLostInfo])}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['eventDate'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

                <Col sm={12} md={6} lg={6}>
                    <FormGroup error={errors['eventCountry']} mandatory={true}>
                        <Form.Label>{t('event_country')}</Form.Label>
                        <CountryDropdown
                            id={"eventCountry"}
                            value={getOrDefault(lostInfo?.eventCountry, "PT")}
                            isDisabled={readOnly}
                            onChange={useCallback((country) => {
                                setLostInfo((info) => ({
                                    ...info,
                                    eventCountry: country,
                                }))
                            }, [setLostInfo])}
                        />

                        <Form.Text className="sub-error">
                            {t(errors['eventCountry'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

                {(lostInfo?.eventCountry === "PT") &&
                <Col sm={12} md={12} lg={12}>
                    <GeofenceForm
                        t={t}
                        readOnly={readOnly}
                        ignoreSubmit={true}
                        isMulti={false}
                        errors={{
                            districts: (errors['eventCounty'] && !eventGeoArea?.districts) ? "INVALID_DISTRICT" : undefined,
                            counties: errors['eventCounty'],
                        }}
                        mandatory={{
                            district: true,
                            county: true,
                        }}
                        entry={eventGeoArea}
                        onChange={(value) => {
                            setLostInfo((info) => ({
                                ...info,
                                eventDistrict: value?.districts,
                                eventCounty: value?.counties,
                                eventParish: value?.parishes,

                            }));
                        }}
                    />
                </Col>
                }

                <Col sm={12} md={12} lg={12}>
                    <FormGroup error={errors['eventLocal']} mandatory={true}>
                        <Form.Label>{t('lost_local')}</Form.Label>
                        <FormControl
                            type="text"
                            placeholder={t('local_ph')}
                            value={getOrEmpty(lostInfo?.eventLocal)}
                            disabled={readOnly}
                            onChange={useCallback((e) => {
                                setLostInfo((info) => ({
                                    ...info,
                                    eventLocal: e.target.value,
                                }))
                            }, [setLostInfo])}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['eventLocal'])}
                        </Form.Text>
                    </FormGroup>
                </Col>

            </Row>
        </Box>


        {!readOnly &&
        <PhotoComponent
            errors={errors}
            readOnly={readOnly}
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
        />
        }


        {(!readOnly || lostInfo?.observations) &&
        <Box>
            <BoxTitle>{t('observations')}</BoxTitle>

            <Row>
                <Col sm={12} xl={12}>
                    <FormGroup error={errors['observations']} mandatory={false}>
                        <Form.Label>{t('observations')}</Form.Label>
                        <Form.Control
                            type="textarea"
                            as="textarea"
                            disabled={readOnly}
                            rows={3}
                            value={getOrEmpty(lostInfo?.observations)}
                            onChange={handleObservations}

                        />
                        <Form.Text className="sub-error">
                            {t(errors['observations'])}
                        </Form.Text>
                    </FormGroup>
                </Col>
            </Row>
        </Box>
        }


        {(!readOnly || (readOnly && lostInfo?.status !== AnimalStatus.FOUND)) &&
        <FormParticipantDataComponent
            lostInfo={lostInfo}
            setLostInfo={setLostInfo}
            errors={errors}
            readOnly={readOnly}
        />
        }


        {!readOnly &&
        <Box>
            <BoxTitle>{t('allow_advertising')}</BoxTitle>
            <Col sm={12} xl={12}>
                <FormGroup error={errors['allowsAdvertising']} mandatory={false}>
                    <CheckBox
                        checked={getOrDefault(lostInfo?.allowsAdvertising, false)}
                        label={t('allow_advertising_msg')}
                        onChange={handleAllowAdvertising}
                    />

                    <Form.Text className="sub-error">
                        {t(errors['allowsAdvertising'])}
                    </Form.Text>
                </FormGroup>
            </Col>
        </Box>
        }
    </>
}

export default FormDataComponent;
