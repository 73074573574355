import useTranslations from "system/Translations/UseTranslations";
import locale from "locale/locales";
import logo from "layout/assets/images/logo.png";
import "./BottomBanner.scss";
import {Trans} from "react-i18next";
import {faArrowPointer, faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const BottomBanner = props => {


    const {t} = useTranslations("global", locale)

    return <div className={"bottom-banner"}>

        <div className={"left"}>

            <div className={"logo"}>
                <img src={logo} className="logo" alt={"logo"}/>
            </div>

            <div className={"txt-light"}>
                <Trans>
                    {t('bottom_siac_desc')}
                </Trans>
            </div>

            <div className={"txt-medium"}>
                <Trans>
                    {t('bottom_siac_desc_2')}
                </Trans>
            </div>


        </div>

        <div className={"center"}>
            <div className={"title"}>
                {t('links')}
            </div>

            <div className={"link"}>
                <a href={"https://www.siac.vet/"}>
                    O SIAC
                </a>
            </div>


            <div className={"link"}>
                <a href={"https://www.siac.vet/faq/"}>
                    FAQ
                </a>
            </div>

        </div>

        <div className={"right"}>

            <div className={"contact-items"}>

                <div className={"contact-block"}>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </div>

                    <div className={"data"}>
                        <div className={"label"}>
                            {t('email')}
                        </div>
                        <div className={"value"}>
                            geral@siac.vet
                        </div>
                    </div>
                </div>


                <div className={"contact-block"}>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faPhone}/>
                    </div>

                    <div className={"data"}>
                        <div className={"label"}>
                            {t('phone')}
                        </div>
                        <div className={"value"}>
                            +351 212 697 878
                        </div>
                    </div>
                </div>


                <div className={"contact-block"}>
                    <div className={"icon"}>
                        <FontAwesomeIcon icon={faArrowPointer}/>
                    </div>

                    <div className={"data"}>
                        <div className={"label"}>
                            {t('address')}
                        </div>
                        <div className={"value"}>
                            Rua Jaime Lopes Dias, 3 A/B<br/>
                            1750-124 LISBOA
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

}

export default BottomBanner;
