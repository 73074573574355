import {useEffect, useState} from "react";
import {cloneWithoutKey} from "../Objects/Objects";
import {useLocation} from "react-router-dom";

const useHistoryState = (key, initialValue) => {

    const location = useLocation();
    const value = window.history.state?.state?.[key];

    const [rawState, setRawState] = useState(value ? value : initialValue);

    useEffect(() => {
        window.history.replaceState({
                ...window.history.state,
                state: {
                    ...window.history.state?.state,
                    [key]: rawState
                },
            },
            null,
            location.pathname,
        );
    }, [rawState, key, location?.pathname]);

    return [rawState, setRawState];
}

const removeVarFromState = (key) => {

    window.history.replaceState({
            ...window.history.state,
            state: {
                ...cloneWithoutKey(window.history.state?.state, key)
            },
        },
        null,
        window.location.pathname,
    );
}

const setVarOnState = (key, value) => {
    window.history.replaceState({
            ...window.history.state,
            state: {
                ...window.history.state?.state,
                [key]: value,
            },
        },
        null,
        window.location.pathname,
    );
};

const useVarFromState = (key) => window.history.state?.state?.[key];

export {removeVarFromState, useVarFromState, setVarOnState};
export default useHistoryState
