import {DayPicker} from "react-day-picker";
import i18next from "i18next";
import portuguese from "date-fns/esm/locale/pt-BR";
import english from "date-fns/locale/en-US";
import React, {useCallback, useMemo} from "react";
import Fade from "react-bootstrap/Fade";
import {getDateObject, getDateString} from "modules/Form/DatePicker/utils/Utils";

const CalendarComponent = props => {

    const {open, setOpen, value, fromDate, toDate, onChange} = props;
    const valueDate = useMemo(() => getDateObject(value), [value]);

    return <Fade in={open} unmountOnExit>
        <div className="date-picker-calendar">
            <DayPicker
                mode="single"
                captionLayout="dropdown"
                required
                locale={i18next.language === "pt-PT" ? portuguese : english}
                key={value}
                selected={valueDate}
                defaultMonth={valueDate}
                toDate={getDateObject(toDate)}
                fromYear={!fromDate ? 2000 : undefined}
                toYear={!toDate ? new Date().getFullYear() : undefined}
                fromDate={getDateObject(fromDate)}
                days={useMemo(() => ({
                    selected: valueDate,
                }), [valueDate])}
                onSelect={useCallback((day) => {
                    onChange(getDateString(day));
                    setOpen(false);
                }, [setOpen, onChange])}


            />
        </div>
    </Fade>
}

export default CalendarComponent;
