import React, {useCallback, useEffect, useMemo, useRef} from "react";
import FormControlTextComponent from "./components/FormControlTextComponent";
import FormControlWithEmptyValue from "./components/FormControlWithEmptyValue";

import './assets/css/FormControl.scss';

const TextComponentPattern = /^(password|email|text)$/

const FormControlComponent = React.forwardRef((props, ref) => {

    const {defaultValue, value, type, className, onChange} = props;
    const [internalValue, setInternalValue] = React.useState(defaultValue ? defaultValue : value);

    const localRef = useRef(null);
    const inputTextRef = ref ? ref : localRef;

    const isSpecialTextComponent = useMemo(
        () => type.match(TextComponentPattern),
        [type]
    );

    const handleOnChange = useCallback((event) => {
        if (onChange)
            onChange(event);

        setInternalValue(event.target.value);

    }, [setInternalValue, onChange]);

    const handleOnClear = useCallback(() => {

        if (onChange)
            onChange({target: {value: ''}, clear: true});

        inputTextRef.current.value = '';
        setInternalValue('');

    }, [setInternalValue, onChange, inputTextRef]);

    useEffect(() =>
            setInternalValue(defaultValue ? defaultValue : value),
        [defaultValue, value]
    );

    if (isSpecialTextComponent) {
        return <FormControlTextComponent
            {...props}
            currentValue={internalValue}
            type={type}
            ref={inputTextRef}
            className={className}
            onChange={handleOnChange}
            onClear={handleOnClear}
        />
    } else
        return <FormControlWithEmptyValue {...props}/>

});

export default FormControlComponent;

