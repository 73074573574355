const countryList = [{
    "code": "AF",
    "name": {
        "en_US": "Afghanistan",
        "pt_PT": "Afeganistão",
    }
}, {
    "code": "AX",
    "name": {
        "en_US": "Aland Islands",
        "pt_PT": "Ilhas Aland",
    }
}, {
    "code": "AL",
    "name": {
        "en_US": "Albania",
        "pt_PT": "Albânia",
    }
}, {
    "code": "DZ",
    "name": {
        "en_US": "Algeria",
        "pt_PT": "Argélia",
    }
}, {
    "code": "AS",
    "name": {
        "en_US": "American Samoa",
        "pt_PT": "Samoa Americana",
    }
}, {
    "code": "AD",
    "name": {
        "en_US": "Andorra",
        "pt_PT": "Andorra",
    }
}, {
    "code": "AO",
    "name": {
        "en_US": "Angola",
        "pt_PT": "Angola",
    }
}, {
    "code": "AI",
    "name": {
        "en_US": "Anguilla",
        "pt_PT": "Anguilla",
    }
}, {
    "code": "AQ",
    "name": {
        "en_US": "Antarctica",
        "pt_PT": "Antártica",
    }
}, {
    "code": "AG",
    "name": {
        "en_US": "Antigua and Barbuda",
        "pt_PT": "Antígua e Barbuda",
    }
}, {
    "code": "AR",
    "name": {
        "en_US": "Argentina",
        "pt_PT": "Argentina",
    }
}, {
    "code": "AM",
    "name": {
        "en_US": "Armenia",
        "pt_PT": "Armenia",
    }
}, {
    "code": "AW",
    "name": {
        "en_US": "Aruba",
        "pt_PT": "Aruba",
    }
}, {
    "code": "AU",
    "name": {
        "en_US": "Australia",
        "pt_PT": "Austrália",
    }
}, {
    "code": "AT",
    "name": {
        "en_US": "Austria",
        "pt_PT": "Áustria",
    }
}, {
    "code": "AZ",
    "name": {
        "en_US": "Azerbaijan",
        "pt_PT": "Azerbaijão",
    }
}, {
    "code": "BS",
    "name": {
        "en_US": "Bahamas",
        "pt_PT": "Bahamas",
    }
}, {
    "code": "BH",
    "name": {
        "en_US": "Bahrain",
        "pt_PT": "Bahrein",
    }
}, {
    "code": "BD",
    "name": {
        "en_US": "Bangladesh",
        "pt_PT": "Bangladesh",
    }
}, {
    "code": "BB",
    "name": {
        "en_US": "Barbados",
        "pt_PT": "Barbados",
    }
}, {
    "code": "BY",
    "name": {
        "en_US": "Belarus",
        "pt_PT": "Bielorrússia",
    }
}, {
    "code": "BE",
    "name": {
        "en_US": "Belgium",
        "pt_PT": "Bélgica",
    }
}, {
    "code": "BZ",
    "name": {
        "en_US": "Belize",
        "pt_PT": "Belize",
    }
}, {
    "code": "BJ",
    "name": {
        "en_US": "Benin",
        "pt_PT": "Benin",
    }
}, {
    "code": "BM",
    "name": {
        "en_US": "Bermuda",
        "pt_PT": "Bermudas",
    }
}, {
    "code": "BT",
    "name": {
        "en_US": "Bhutan",
        "pt_PT": "Butão",
    }
}, {
    "code": "BO",
    "name": {
        "en_US": "Bolivia, Plurinational State of",
        "pt_PT": "Bolívia, Estado Plurinacional da",
    }
}, {
    "code": "BQ",
    "name": {
        "en_US": "Bonaire, Sint Eustatius and Saba",
        "pt_PT": "Bonaire, Sint Eustatius e Saba",
    }
}, {
    "code": "BA",
    "name": {
        "en_US": "Bosnia and Herzegovina",
        "pt_PT": "Bósnia e Herzegovina",
    }
}, {
    "code": "BW",
    "name": {
        "en_US": "Botswana",
        "pt_PT": "Botswana",
    }
}, {
    "code": "BV",
    "name": {
        "en_US": "Bouvet Island",
        "pt_PT": "Ilha Bouvet",
    }
}, {
    "code": "BR",
    "name": {
        "en_US": "Brazil",
        "pt_PT": "Brasil",
    }
}, {
    "code": "IO",
    "name": {
        "en_US": "British Indian Ocean Territory",
        "pt_PT": "Território Britânico Do Oceano Índico",
    }
}, {
    "code": "BN",
    "name": {
        "en_US": "Brunei Darussalam",
        "pt_PT": "Brunei Darussalam",
    }
}, {
    "code": "BG",
    "name": {
        "en_US": "Bulgaria",
        "pt_PT": "Bulgária",
    }
}, {
    "code": "BF",
    "name": {
        "en_US": "Burkina Faso",
        "pt_PT": "Burkina Faso",
    }
}, {
    "code": "BI",
    "name": {
        "en_US": "Burundi",
        "pt_PT": "Burundi",
    }
}, {
    "code": "KH",
    "name": {
        "en_US": "Cambodia",
        "pt_PT": "Camboja",
    }
}, {
    "code": "CM",
    "name": {
        "en_US": "Cameroon",
        "pt_PT": "Camarões",
    }
}, {
    "code": "CA",
    "name": {
        "en_US": "Canada",
        "pt_PT": "Canada",
    }
}, {
    "code": "CV",
    "name": {
        "en_US": "Cape Verde",
        "pt_PT": "Cabo Verde",
    }
}, {
    "code": "KY",
    "name": {
        "en_US": "Cayman Islands",
        "pt_PT": "Ilhas Cayman",
    }
}, {
    "code": "CF",
    "name": {
        "en_US": "Central African Republic",
        "pt_PT": "Central Africano República",
    }
}, {
    "code": "TD",
    "name": {
        "en_US": "Chad",
        "pt_PT": "Chade",
    }
}, {
    "code": "CL",
    "name": {
        "en_US": "Chile",
        "pt_PT": "Chile",
    }
}, {
    "code": "CN",
    "name": {
        "en_US": "China",
        "pt_PT": "China, República Popular da",
    }
}, {
    "code": "CX",
    "name": {
        "en_US": "Christmas Island",
        "pt_PT": "Ilha Christmas",
    }
}, {
    "code": "CC",
    "name": {
        "en_US": "Cocos (Keeling) Islands",
        "pt_PT": "Ilhas Cocos (Keeling) ",
    }
}, {
    "code": "CO",
    "name": {
        "en_US": "Colombia",
        "pt_PT": "Colômbia",
    }
}, {
    "code": "KM",
    "name": {
        "en_US": "Comoros",
        "pt_PT": "Comores",
    }
}, {
    "code": "CG",
    "name": {
        "en_US": "Congo",
        "pt_PT": "Congo",
    }
}, {
    "code": "CD",
    "name": {
        "en_US": "Democratic Republic of the Congo",
        "pt_PT": "República Democrática do Congo",
    }
}, {
    "code": "CK",
    "name": {
        "en_US": "Cook Islands",
        "pt_PT": "Ilhas Cook",
    }
}, {
    "code": "CR",
    "name": {
        "en_US": "Costa Rica",
        "pt_PT": "Costa Rica",
    }
}, {
    "code": "CI",
    "name": {
        "en_US": "Côte d'Ivoire",
        "pt_PT": "Costa do Marfim",
    }
}, {
    "code": "HR",
    "name": {
        "en_US": "Croatia",
        "pt_PT": "Croácia",
    }
}, {
    "code": "CU",
    "name": {
        "en_US": "Cuba",
        "pt_PT": "Cuba",
    }
}, {
    "code": "CW",
    "name": {
        "en_US": "Curaçao",
        "pt_PT": "Curaçau",
    }
}, {
    "code": "CY",
    "name": {
        "en_US": "Cyprus",
        "pt_PT": "Chipre",
    }
}, {
    "code": "CZ",
    "name": {
        "en_US": "Czech Republic",
        "pt_PT": "República Checa",
    }
}, {
    "code": "DK",
    "name": {
        "en_US": "Denmark",
        "pt_PT": "Dinamarca",
    }
}, {
    "code": "DJ",
    "name": {
        "en_US": "Djibouti",
        "pt_PT": "Djibuti",
    }
}, {
    "code": "DM",
    "name": {
        "en_US": "Dominica",
        "pt_PT": "Dominica",
    }
}, {
    "code": "DO",
    "name": {
        "en_US": "Dominican Republic",
        "pt_PT": "República Dominicana",
    }
}, {
    "code": "EC",
    "name": {
        "en_US": "Ecuador",
        "pt_PT": "Equador",
    }
}, {
    "code": "EG",
    "name": {
        "en_US": "Egypt",
        "ca": "Egipte",
        "cs": "Egypt",
        "da": "Egypten",
        "de": "Ägypten",
        "es": "Egipto",
        "et": "Egiptus",
        "fi": "Egypti",
        "fr": "L'egypte",
        "hu": "Egyiptom",
        "lt": "Egiptas",
        "lv": "Ēģipte",
        "nl": "Egypte",
        "no": "Egypt",
        "pt_PT": "Egipto",
        "sk": "Egypt",
        "sl": "Egipt",
        "sq": "Egjipt",
        "sv": "Egypten",
    }
}, {
    "code": "SV",
    "name": {
        "en_US": "El Salvador",
        "pt_PT": "El Salvador",
    }
}, {
    "code": "GQ",
    "name": {
        "en_US": "Equatorial Guinea",
        "pt_PT": "Guiné Equatorial",
    }
}, {
    "code": "ER",
    "name": {
        "en_US": "Eritrea",
        "pt_PT": "Eritreia",
    }
}, {
    "code": "EE",
    "name": {
        "en_US": "Estonia",
        "pt_PT": "Estónia",
    }
}, {
    "code": "ET",
    "name": {
        "en_US": "Ethiopia",
        "pt_PT": "Etiópia",
    }
}, {
    "code": "FK",
    "name": {
        "en_US": "Falkland Islands (Malvinas)",
        "pt_PT": "Ilhas Falkland (Ilhas Malvinas)",
    }
}, {
    "code": "FO",
    "name": {
        "en_US": "Faroe Islands",
        "pt_PT": "Ilhas Faroé",
    }
}, {
    "code": "FJ",
    "name": {
        "en_US": "Fiji",
        "pt_PT": "Fiji",
    }
}, {
    "code": "FI",
    "name": {
        "en_US": "Finland",
        "pt_PT": "Finlândia",
    }
}, {
    "code": "FR",
    "name": {
        "en_US": "France",
        "pt_PT": "França",
    }
}, {
    "code": "GF",
    "name": {
        "en_US": "French Guiana",
        "pt_PT": "Guiana Francesa",
    }
}, {
    "code": "PF",
    "name": {
        "en_US": "French Polynesia",
        "pt_PT": "Polinésia Francesa",
    }
}, {
    "code": "TF",
    "name": {
        "en_US": "French Southern Territories",
        "pt_PT": "Territórios Franceses Do Sul",
    }
}, {
    "code": "GA",
    "name": {
        "en_US": "Gabon",
        "pt_PT": "Gabão",
    }
}, {
    "code": "GM",
    "name": {
        "en_US": "Gambia",
        "pt_PT": "Gâmbia",
    }
}, {
    "code": "GE",
    "name": {
        "en_US": "Georgia",
        "pt_PT": "Geórgia",
    }
}, {
    "code": "DE",
    "name": {
        "en_US": "Germany",
        "pt_PT": "Alemanha",
    }
}, {
    "code": "GH",
    "name": {
        "en_US": "Ghana",
        "pt_PT": "Gana",
    }
}, {
    "code": "GI",
    "name": {
        "en_US": "Gibraltar",
        "pt_PT": "Gibraltar",
    }
}, {
    "code": "GR",
    "name": {
        "en_US": "Greece",
        "pt_PT": "Grécia",
    }
}, {
    "code": "GL",
    "name": {
        "en_US": "Greenland",
        "pt_PT": "Gronelândia",
    }
}, {
    "code": "GD",
    "name": {
        "en_US": "Grenada",
        "pt_PT": "Granada",
    }
}, {
    "code": "GP",
    "name": {
        "en_US": "Guadeloupe",
        "pt_PT": "Guadalupe",
    }
}, {
    "code": "GU",
    "name": {
        "en_US": "Guam",
        "pt_PT": "Guam",
    }
}, {
    "code": "GT",
    "name": {
        "en_US": "Guatemala",
        "pt_PT": "Guatemala",
    }
}, {
    "code": "GG",
    "name": {
        "en_US": "Guernsey",
        "pt_PT": "Guernsey",
    }
}, {
    "code": "GN",
    "name": {
        "en_US": "Guinea",
        "pt_PT": "Guiné",
    }
}, {
    "code": "GW",
    "name": {
        "en_US": "Guinea-Bissau",
        "pt_PT": "Guiné-Bissau",
    }
}, {
    "code": "GY",
    "name": {
        "en_US": "Guyana",
        "pt_PT": "Guiana",
    }
}, {
    "code": "HT",
    "name": {
        "en_US": "Haiti",
        "pt_PT": "Haiti",
    }
}, {
    "code": "HM",
    "name": {
        "en_US": "Heard Island and McDonald Islands",
        "pt_PT": "Ilha Heard e Ilhas McDonald",
    }
}, {
    "code": "VA",
    "name": {
        "en_US": "Holy See (Vatican City State)",
        "pt_PT": "Santa Sé (Cidade Do Vaticano)",
    }
}, {
    "code": "HN",
    "name": {
        "en_US": "Honduras",
        "pt_PT": "Honduras",
    }
}, {
    "code": "HK",
    "name": {
        "en_US": "Hong Kong",
        "pt_PT": "Hong Kong",
    }
}, {
    "code": "HU",
    "name": {
        "en_US": "Hungary",
        "pt_PT": "Hungria",
    }
}, {
    "code": "IS",
    "name": {
        "en_US": "Iceland",
        "pt_PT": "Islândia",
    }
}, {
    "code": "IN",
    "name": {
        "en_US": "India",
        "pt_PT": "Índia",
    }
}, {
    "code": "ID",
    "name": {
        "en_US": "Indonesia",
        "pt_PT": "Indonésia",
    }
}, {
    "code": "IR",
    "name": {
        "en_US": "Iran, Islamic Republic of",
        "pt_PT": "República Islâmica do Irão",
    }
}, {
    "code": "IQ",
    "name": {
        "en_US": "Iraq",
        "pt_PT": "Iraque",
    }
}, {
    "code": "IE",
    "name": {
        "en_US": "Ireland",
        "pt_PT": "Irlanda",
    }
}, {
    "code": "IM",
    "name": {
        "en_US": "Isle of Man",
        "pt_PT": "Ilha de Man",
    }
}, {
    "code": "IL",
    "name": {
        "en_US": "Israel",
        "pt_PT": "Israel",
    }
}, {
    "code": "IT",
    "name": {
        "en_US": "Italy",
        "pt_PT": "Itália",
    }
}, {
    "code": "JM",
    "name": {
        "en_US": "Jamaica",
        "pt_PT": "Jamaica",
    }
}, {
    "code": "JP",
    "name": {
        "en_US": "Japan",
        "pt_PT": "Japão",
    }
}, {
    "code": "JE",
    "name": {
        "en_US": "Jersey",
        "pt_PT": "Jersey",
    }
}, {
    "code": "JO",
    "name": {
        "en_US": "Jordan",
        "pt_PT": "Jordânia",
    }
}, {
    "code": "KZ",
    "name": {
        "en_US": "Kazakhstan",
        "pt_PT": "Cazaquistão",
    }
}, {
    "code": "KE",
    "name": {
        "en_US": "Kenya",
        "pt_PT": "Quênia",
    }
}, {
    "code": "KI",
    "name": {
        "en_US": "Kiribati",
        "pt_PT": "Kiribati",
    }
}, {
    "code": "KP",
    "name": {
        "en_US": "Korea, Democratic People's Republic of",
        "pt_PT": "Republica popular Democrática da Coreia (Coreia do Norte)",
    }
}, {
    "code": "KR",
    "name": {
        "en_US": "Republic of Korea",
        "pt_PT": "República da Coréia",
    }
}, {
    "code": "KW",
    "name": {
        "en_US": "Kuwait",
        "pt_PT": "Kuwait",
    }
}, {
    "code": "KG",
    "name": {
        "en_US": "Kyrgyzstan",
        "pt_PT": "Quirguistão",
    }
}, {
    "code": "LA",
    "name": {
        "en_US": "Lao People's Democratic Republic",
        "pt_PT": "República Democrática popular do laos",
    }
}, {
    "code": "LV",
    "name": {
        "en_US": "Latvia",
        "pt_PT": "Letónia",
    }
}, {
    "code": "LB",
    "name": {
        "en_US": "Lebanon",
        "pt_PT": "Líbano",
    }
}, {
    "code": "LS",
    "name": {
        "en_US": "Lesotho",
        "pt_PT": "Lesoto",
    }
}, {
    "code": "LR",
    "name": {
        "en_US": "Liberia",
        "pt_PT": "Libéria",
    }
}, {
    "code": "LY",
    "name": {
        "en_US": "Libya",
        "pt_PT": "Líbia",
    }
}, {
    "code": "LI",
    "name": {
        "en_US": "Liechtenstein",
        "pt_PT": "Liechtenstein",
    }
}, {
    "code": "LT",
    "name": {
        "en_US": "Lithuania",
        "pt_PT": "Lituânia",
    }
}, {
    "code": "LU",
    "name": {
        "en_US": "Luxembourg",
        "pt_PT": "Luxemburgo",
    }
}, {
    "code": "MO",
    "name": {
        "en_US": "Macao",
        "pt_PT": "Macau",
    }
}, {
    "code": "MK",
    "name": {
        "en_US": "Former Yugoslav Republic of Macedonia",
        "pt_PT": "Antiga República Jugoslava da Macedônia",
    }
}, {
    "code": "MG",
    "name": {
        "en_US": "Madagascar",
        "pt_PT": "Madagascar",
    }
}, {
    "code": "MW",
    "name": {
        "en_US": "Malawi",
        "pt_PT": "Malawi",
    }
}, {
    "code": "MY",
    "name": {
        "en_US": "Malaysia",
        "pt_PT": "Malásia",
    }
}, {
    "code": "MV",
    "name": {
        "en_US": "Maldives",
        "pt_PT": "Maldivas",
    }
}, {
    "code": "ML",
    "name": {
        "en_US": "Mali",
        "pt_PT": "Mali",
    }
}, {
    "code": "MT",
    "name": {
        "en_US": "Malta",
        "pt_PT": "Malta",
    }
}, {
    "code": "MH",
    "name": {
        "en_US": "Marshall Islands",
        "pt_PT": "Ilhas Marshall",
    }
}, {
    "code": "MQ",
    "name": {
        "en_US": "Martinique",
        "pt_PT": "Martinica",
    }
}, {
    "code": "MR",
    "name": {
        "en_US": "Mauritania",
        "pt_PT": "Mauritânia",
    }
}, {
    "code": "MU",
    "name": {
        "en_US": "Mauritius",
        "pt_PT": "Maurício",
    }
}, {
    "code": "YT",
    "name": {
        "en_US": "Mayotte",
        "pt_PT": "Mayotte",
    }
}, {
    "code": "MX",
    "name": {
        "en_US": "Mexico",
        "pt_PT": "México",
    }
}, {
    "code": "FM",
    "name": {
        "en_US": "Federated States of Micronesia",
        "pt_PT": "Estados Federados da Micronésia",
    }
}, {
    "code": "MD",
    "name": {
        "en_US": "Republic of Moldova",
        "pt_PT": "República da Moldávia",
    }
}, {
    "code": "MC",
    "name": {
        "en_US": "Monaco",
        "pt_PT": "Monaco",
    }
}, {
    "code": "MN",
    "name": {
        "en_US": "Mongolia",
        "pt_PT": "Mongólia",
    }
}, {
    "code": "ME",
    "name": {
        "en_US": "Montenegro",
        "pt_PT": "Montenegro",
    }
}, {
    "code": "MS",
    "name": {
        "en_US": "Montserrat",
        "pt_PT": "Montserrat",
    }
}, {
    "code": "MA",
    "name": {
        "en_US": "Morocco",
        "pt_PT": "Marrocos",
    }
}, {
    "code": "MZ",
    "name": {
        "en_US": "Mozambique",
        "pt_PT": "Moçambique",
    }
}, {
    "code": "MM",
    "name": {
        "en_US": "Myanmar",
        "pt_PT": "Mianmar",
    }
}, {
    "code": "NA",
    "name": {
        "en_US": "Namibia",
        "pt_PT": "Namíbia",
    }
}, {
    "code": "NR",
    "name": {
        "en_US": "Nauru",
        "pt_PT": "Nauru",
    }
}, {
    "code": "NP",
    "name": {
        "en_US": "Nepal",
        "pt_PT": "Nepal",
    }
}, {
    "code": "NL",
    "name": {
        "en_US": "Netherlands",
        "pt_PT": "Países baixos",
    }
}, {
    "code": "NC",
    "name": {
        "en_US": "New Caledonia",
        "pt_PT": "Nova Caledônia",
    }
}, {
    "code": "NZ",
    "name": {
        "en_US": "New Zealand",
        "pt_PT": "Nova Zelândia",
    }
}, {
    "code": "NI",
    "name": {
        "en_US": "Nicaragua",
        "pt_PT": "Nicarágua",
    }
}, {
    "code": "NE",
    "name": {
        "en_US": "Niger",
        "pt_PT": "Níger",
    }
}, {
    "code": "NG",
    "name": {
        "en_US": "Nigeria",
        "pt_PT": "Nigéria",
    }
}, {
    "code": "NU",
    "name": {
        "en_US": "Niue",
        "pt_PT": "Niue",
    }
}, {
    "code": "NF",
    "name": {
        "en_US": "Norfolk Island",
        "pt_PT": "Ilha Norfolk",
    }
}, {
    "code": "MP",
    "name": {
        "en_US": "Northern Mariana Islands",
        "pt_PT": "Ilhas Marianas Do Norte",
    }
}, {
    "code": "NO",
    "name": {
        "en_US": "Norway",
        "pt_PT": "Noruega",
    }
}, {
    "code": "OM",
    "name": {
        "en_US": "Oman",
        "pt_PT": "Omã",
    }
}, {
    "code": "PK",
    "name": {
        "en_US": "Pakistan",
        "pt_PT": "Paquistão",
    }
}, {
    "code": "PW",
    "name": {
        "en_US": "Palau",
        "pt_PT": "Palau",
    }
}, {
    "code": "PS",
    "name": {
        "en_US": "State of Palestine",
        "pt_PT": "Estado da Palestina",
    }
}, {
    "code": "PA",
    "name": {
        "en_US": "Panama",
        "pt_PT": "Panamá",
    }
}, {
    "code": "PG",
    "name": {
        "en_US": "Papua New Guinea",
        "pt_PT": "Papua-Nova Guiné",
    }
}, {
    "code": "PY",
    "name": {
        "en_US": "Paraguay",
        "pt_PT": "Paraguai",
    }
}, {
    "code": "PE",
    "name": {
        "en_US": "Peru",
        "pt_PT": "Peru",
    }
}, {
    "code": "PH",
    "name": {
        "en_US": "Philippines",
        "pt_PT": "Filipinas",
    }
}, {
    "code": "PN",
    "name": {
        "en_US": "Pitcairn",
        "pt_PT": "Pitcairn",
    }
}, {
    "code": "PL",
    "name": {
        "en_US": "Poland",
        "pt_PT": "Polónia",
    }
}, {
    "code": "PT",
    "name": {
        "en_US": "Portugal",
        "pt_PT": "Portugal",
    }
}, {
    "code": "PR",
    "name": {
        "en_US": "Puerto Rico",
        "pt_PT": "Porto Rico",
    }
}, {
    "code": "QA",
    "name": {
        "en_US": "Qatar",
        "pt_PT": "Catar",
    }
}, {
    "code": "RE",
    "name": {
        "en_US": "Réunion",
        "pt_PT": "Reunião",
    }
}, {
    "code": "RO",
    "name": {
        "en_US": "Romania",
        "pt_PT": "Roménia",
    }
}, {
    "code": "RU",
    "name": {
        "en_US": "Russian Federation",
        "pt_PT": "Federação Russa",
    }
}, {
    "code": "RW",
    "name": {
        "en_US": "Rwanda",
        "pt_PT": "Ruanda",
    }
}, {
    "code": "BL",
    "name": {
        "en_US": "Saint Barthélemy",
        "pt_PT": "São Bartolomeu",
    }
}, {
    "code": "SH",
    "name": {
        "en_US": "Saint Helena, Ascension and Tristan da Cunha",
        "pt_PT": "Santa Helena, Ascensão e Tristão da Cunha",
    }
}, {
    "code": "KN",
    "name": {
        "en_US": "Saint Kitts and Nevis",
        "pt_PT": "São Cristóvão e Nevis",
    }
}, {
    "code": "LC",
    "name": {
        "en_US": "Saint Lucia",
        "pt_PT": "Santa Lúcia",
    }
}, {
    "code": "MF",
    "name": {
        "en_US": "Saint Martin (French part)",
        "pt_PT": "Saint Martin (parte Francesa)",
    }
}, {
    "code": "PM",
    "name": {
        "en_US": "Saint Pierre and Miquelon",
        "pt_PT": "Saint Pierre e Miquelon",
    }
}, {
    "code": "VC",
    "name": {
        "en_US": "Saint Vincent and the Grenadines",
        "pt_PT": "São Vicente e Granadinas",
    }
}, {
    "code": "WS",
    "name": {
        "en_US": "Samoa",
        "pt_PT": "Samoa",
    }
}, {
    "code": "SM",
    "name": {
        "en_US": "San Marino",
        "pt_PT": "San Marino",
    }
}, {
    "code": "ST",
    "name": {
        "en_US": "Sao Tome and Principe",
        "pt_PT": "São Tomé e Príncipe",
    }
}, {
    "code": "SA",
    "name": {
        "en_US": "Saudi Arabia",
        "pt_PT": "Arábia Saudita",
    }
}, {
    "code": "SN",
    "name": {
        "en_US": "Senegal",
        "pt_PT": "Senegal",
    }
}, {
    "code": "RS",
    "name": {
        "en_US": "Serbia",
        "pt_PT": "Sérvia",
    }
}, {
    "code": "SC",
    "name": {
        "en_US": "Seychelles",
        "pt_PT": "Seychelles",
    }
}, {
    "code": "SL",
    "name": {
        "en_US": "Sierra Leone",
        "pt_PT": "Serra Leoa",
    }
}, {
    "code": "SG",
    "name": {
        "en_US": "Singapore",
        "pt_PT": "Singapura",
    }
}, {
    "code": "SX",
    "name": {
        "en_US": "Sint Maarten (Dutch part)",
        "pt_PT": "Sint Maarten (parte holandesa)",
    }
}, {
    "code": "SK",
    "name": {
        "en_US": "Slovakia",
        "pt_PT": "Eslováquia",
    }
}, {
    "code": "SI",
    "name": {
        "en_US": "Slovenia",
        "pt_PT": "Eslovénia",
    }
}, {
    "code": "SB",
    "name": {
        "en_US": "Solomon Islands",
        "pt_PT": "Ilhas Salomão",
    }
}, {
    "code": "SO",
    "name": {
        "en_US": "Somalia",
        "pt_PT": "Somália",
    }
}, {
    "code": "ZA",
    "name": {
        "en_US": "South Africa",
        "pt_PT": "África Do Sul",
    }
}, {
    "code": "GS",
    "name": {
        "en_US": "South Georgia and the South Sandwich Islands",
        "pt_PT": "Geórgia do sul e as Ilhas Sanduiche do Sul",
    }
}, {
    "code": "SS",
    "name": {
        "en_US": "South Sudan",
        "pt_PT": "Sudão Do Sul",
    }
}, {
    "code": "ES",
    "name": {
        "en_US": "Spain",
        "pt_PT": "Espanha",
    }
}, {
    "code": "LK",
    "name": {
        "en_US": "Sri Lanka",
        "pt_PT": "Sri Lanka",
    }
}, {
    "code": "SD",
    "name": {
        "en_US": "Sudan",
        "pt_PT": "Sudão",
    }
}, {
    "code": "SR",
    "name": {
        "en_US": "Suriname",
        "pt_PT": "Suriname",
    }
}, {
    "code": "SJ",
    "name": {
        "en_US": "Svalbard and Jan Mayen",
        "pt_PT": "Svalbard e Jan Mayen",
    }
}, {
    "code": "SZ",
    "name": {
        "en_US": "Swaziland",
        "pt_PT": "Suazilândia",
    }
}, {
    "code": "SE",
    "name": {
        "en_US": "Sweden",
        "pt_PT": "Suécia",
    }
}, {
    "code": "CH",
    "name": {
        "en_US": "Switzerland",
        "pt_PT": "Suíça",
    }
}, {
    "code": "SY",
    "name": {
        "en_US": "Syrian Arab Republic",
        "pt_PT": "República Árabe Da Síria",
    }
}, {
    "code": "TW",
    "name": {
        "en_US": "Taiwan, Province of China",
        "pt_PT": "Taiwan, Província da China",
    }
}, {
    "code": "TJ",
    "name": {
        "en_US": "Tajikistan",
        "pt_PT": "Tajiquistão",
    }
}, {
    "code": "TZ",
    "name": {
        "en_US": "Tanzania, United Republic of",
        "pt_PT": "Tanzânia, República Unida da",
    }
}, {
    "code": "TH",
    "name": {
        "en_US": "Thailand",
        "pt_PT": "Tailândia",
    }
}, {
    "code": "TL",
    "name": {
        "en_US": "Timor-Leste",
        "pt_PT": "Timor-Leste",
    }
}, {
    "code": "TG",
    "name": {
        "en_US": "Togo",
        "pt_PT": "Togo",
    }
}, {
    "code": "TK",
    "name": {
        "en_US": "Tokelau",
        "pt_PT": "Tokelau",
    }
}, {
    "code": "TO",
    "name": {
        "en_US": "Tonga",
        "pt_PT": "Tonga",
    }
}, {
    "code": "TT",
    "name": {
        "en_US": "Trinidad and Tobago",
        "pt_PT": "Trinidad e Tobago",
    }
}, {
    "code": "TN",
    "name": {
        "en_US": "Tunisia",
        "pt_PT": "Tunísia",
    }
}, {
    "code": "TR",
    "name": {
        "en_US": "Turkey",
        "pt_PT": "Turquia",
    }
}, {
    "code": "TM",
    "name": {
        "en_US": "Turkmenistan",
        "pt_PT": "Turcomenistão",
    }
}, {
    "code": "TC",
    "name": {
        "en_US": "Turks and Caicos Islands",
        "pt_PT": "Ilhas turcas e Caicos",
    }
}, {
    "code": "TV",
    "name": {
        "en_US": "Tuvalu",
        "pt_PT": "Tuvalu",
    }
}, {
    "code": "UG",
    "name": {
        "en_US": "Uganda",
        "pt_PT": "Uganda",
    }
}, {
    "code": "UA",
    "name": {
        "en_US": "Ukraine",
        "pt_PT": "Ucrânia",
    }
}, {
    "code": "AE",
    "name": {
        "en_US": "United Arab Emirates",
        "pt_PT": "Emirados Árabes Unidos",
    }
}, {
    "code": "GB",
    "name": {
        "en_US": "United Kingdom",
        "pt_PT": "Reino Unido",
    }
}, {
    "code": "US",
    "name": {
        "en_US": "United States",
        "pt_PT": "Estados Unidos",
    }
}, {
    "code": "UM",
    "name": {
        "en_US": "United States Minor Outlying Islands",
        "pt_PT": "Estados Unidos Ilhas Menores Distantes",
    }
}, {
    "code": "UY",
    "name": {
        "en_US": "Uruguay",
        "pt_PT": "Uruguai",
    }
}, {
    "code": "UZ",
    "name": {
        "en_US": "Uzbekistan",
        "pt_PT": "Uzbequistão",
    }
}, {
    "code": "VU",
    "name": {
        "en_US": "Vanuatu",
        "pt_PT": "Vanuatu",
    }
}, {
    "code": "VE",
    "name": {
        "en_US": "Venezuela, Bolivarian Republic of",
        "pt_PT": "Venezuela (República Bolivariana da)",
    }
}, {
    "code": "VN",
    "name": {
        "en_US": "Viet Nam",
        "pt_PT": "Vietname",
    }
}, {
    "code": "VG",
    "name": {
        "en_US": "Virgin Islands, British",
        "pt_PT": "Ilhas Virgens Britânicas",
    }
}, {
    "code": "VI",
    "name": {
        "en_US": "Virgin Islands, U.S.",
        "pt_PT": "Ilhas virgens americanas",
    }
}, {
    "code": "WF",
    "name": {
        "en_US": "Wallis and Futuna",
        "pt_PT": "Wallis e Futuna",
    }
}, {
    "code": "EH",
    "name": {
        "en_US": "Western Sahara",
        "pt_PT": "Saara Ocidental",
    }
}, {
    "code": "YE",
    "name": {
        "en_US": "Yemen",
        "pt_PT": "Iêmen",
    }
}, {
    "code": "ZM",
    "name": {
        "en_US": "Zambia",
        "pt_PT": "Zâmbia",
    }
}, {
    "code": "ZW",
    "name": {
        "en_US": "Zimbabwe",
        "pt_PT": "Zimbábue",
    }
}];

export default countryList;
