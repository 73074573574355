import {useCallback, useMemo} from "react";
import countryList from "system/Countries/countries";
import appConfig from "configs/config";

const useCountryListOptions = () => {

    return useMemo(() => countryList.map(
            (country) => ({
                value: country.code,
                label: country.name[appConfig.language],
            })),
        []
    );
}


const useCountryTranslation = () => {

    const countries = useMemo(() => countryList.reduce(
            (acc, country) => ({
                ...acc,
                [country.code]: country.name[appConfig.language]
            }), {}),
        []
    );

    return useCallback((countryCode) => countries[countryCode], [countries]);


}

export default useCountryListOptions;
export {useCountryTranslation}
