import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons"
import "./facebook.scss";
import {FacebookShareButton} from "react-share";

const FacebookShare = props => {

    const quote = "Foi encontrado um animal ";
    const hashTag = "SIAC #AnimalPerdido";

    const {label} = props;

    return <>
        <FacebookShareButton
            url={window.location}
            quote={quote}
            hashtag={hashTag}
        >
            <div className={"facebook-share-button"}>
                <FontAwesomeIcon icon={faFacebookF}/>
                <span className={"fb-label"}>
            {label}
            </span>
            </div>
        </FacebookShareButton>
    </>
}

export default FacebookShare;
