import React, {useCallback, useEffect, useState} from "react"
import {useGet} from "system/Rest/Rest"
import useHistoryState from "system/Routing/useHistoryState";

const GenericTableContainer = React.forwardRef((props, ref) => {

    const {
        id = 'genericTable',
        searchParameter = 'universalSearch',
        path,
        queryParameters,
        children
    } = props

    const [searchFilter, setSearchFilter] = useHistoryState(id, queryParameters);
    const [loading, setLoading] = useState(true);

    const {data, error, loading: loadingData, refetch: reloadData} = useGet({
        path: path,
        queryParams: searchFilter,
    });

    //Function to filter results
    const handleFilterSubmit = useCallback((data) => {
        if (!data || Object.keys(data).length === 0) {
            setSearchFilter(queryParameters);
        } else {
            setSearchFilter({
                ...queryParameters,
                ...data,
                currentPage: 0,
            });
        }
    }, [queryParameters, setSearchFilter]);

    useEffect(() => {
        setLoading(loadingData);
    }, [loadingData, setLoading]);

    useEffect(() => {
        if (ref) {
            ref.current = {
                reload: reloadData,
                onFilterSubmit: handleFilterSubmit
            }
        }
    }, [ref, reloadData, handleFilterSubmit]);


    return React.cloneElement(
        children, {
            data: data,
            queryParameters: queryParameters,
            searchFilter: searchFilter,
            loading: loading,
            error: error,
            reload: reloadData,
            onPageChange: useCallback(
                (pageNumber) => setSearchFilter((searchFilter) => ({
                    ...searchFilter,
                    currentPage: pageNumber - 1
                })),
                [setSearchFilter]
            ),
            onPageSort: useCallback(
                (field, direction) => setSearchFilter((searchFilter) => ({
                    ...searchFilter,
                    sortProperty: field,
                    sortDirection: direction.toUpperCase(),
                })),
                [setSearchFilter]
            ),
            onSearch: useCallback(
                (search) => {
                    if (search !== searchFilter[searchParameter]) {
                        setSearchFilter((searchFilter) => ({
                            ...searchFilter,
                            [searchParameter]: search,
                            currentPage: 0,
                        }))
                    }
                },
                [setSearchFilter, searchParameter, searchFilter]
            ),
            onFilterSubmit: handleFilterSubmit
        }
    )

})

export default GenericTableContainer;
