import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {hasLangConfig, setLangConfig} from "configs/i18n/i18n";

const useTranslations = (namespace, localConfig) => {

    const {t} = useTranslation(namespace);
    const [ready, setReady] = useState(false);

    useEffect(
        () => {
            if (!hasLangConfig(namespace, localConfig)) {
                setLangConfig(namespace, localConfig)
            }

        },
        [localConfig, namespace]
    );

    useEffect(
        () => setReady(hasLangConfig(namespace, localConfig)),
        [localConfig, namespace, setReady]
    );

    return {
        t: t,
        ready: ready
    }

}

export default useTranslations;
