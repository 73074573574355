const acceptTypeImages = {
    'image/*': ['.png', '.gif', '.jpeg', '.jpg']
}

const acceptTypesPdf = {
    'application/pdf': ['.pdf']
}

const acceptTypeDocx = {
    'application/*': ['.docx']
}

export {
    acceptTypeImages,
    acceptTypesPdf,
    acceptTypeDocx
}
