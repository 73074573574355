
import React from "react";
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {ReactComponent as LostBall} from "app/AnimalLost/assets/images/balls/Lost.svg";
import {ReactComponent as FoundBall} from "app/AnimalLost/assets/images/balls/Found.svg";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import "app/AnimalLost/assets/status-label.scss";

const StatusLabelComponent = props => {

    const {animal} = props;

    const {t} = useTranslation("animal");

    return <div  className={classnames("animal-card-label", {
        "lost": animal.status === AnimalStatus.LOST,
        "found": animal.status === AnimalStatus.FOUND,
    })}>

        <div className={"icon"}>
            {(animal.status === AnimalStatus.LOST) && <LostBall/>}
            {(animal.status === AnimalStatus.FOUND) && <FoundBall/>}
        </div>

        <div className={"label"}>
            {t(animal.status)}
        </div>

    </div>

}

export default StatusLabelComponent
