import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const GoBack = props => {

    const navigate = useNavigate();

    return <>
        {window.history.length > 1
            ? <div className={"go-back"} onClick={() => navigate((-1))}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </div>
            : <Link to={"/"} className={"go-back"}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Link>
        }

    </>
}

export default GoBack;
