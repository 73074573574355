import React, {useState} from 'react'
import {faFilter} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import SearchControl from "./components/SearchControl";
import {getOrEmpty} from "system/Objects/ObjectParameters";
import './assets/css/search-area.scss'
import './assets/css/search-area-responsive.scss'

const SearchArea = (props) => {

    const {value, onSearch, children, ...otherProps} = props;

    const isSearchFilterPresent = (!!children?.type);
    const [isOpen, setOpen] = useState(false);

    return <div className="search-area">
        <div className="search-area-bar">
            <SearchControl
                {...otherProps}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onSearch(e.target.value)
                    }
                }}
                onBlur={(e) => { onSearch(e.target.value)}}
                onChange={(e) => {
                    if (e.target.value === "")
                        onSearch("");
                }}
                defaultValue={getOrEmpty(value)}/>

            {isSearchFilterPresent &&
            <div
                className={isOpen ? "btn-rounded-primary active" : "btn-rounded-primary"}
                onClick={() => setOpen(!isOpen)}>
                <FontAwesomeIcon icon={faFilter}/>
            </div>
            }

        </div>

        {isSearchFilterPresent && React.cloneElement(children, {
                isOpen: isOpen,
                onClose: () => {
                    setOpen(false)
                },
            }
        )}

    </div>

}

export default SearchArea;
