import React, {useCallback, useEffect, useMemo} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useHistoryState from "system/Routing/useHistoryState";
import useTranslations from "system/Translations/UseTranslations";
import locale from "./locale/locales";
import usePrevious from "system/Utils/usePrevious";
import {cloneWithoutKey, objectIsEqual} from "system/Objects/Objects";
import {useIsMobile} from "system/Window/Mobile";

import "./assets/css/infinite-loader.scss";
import Button from "react-bootstrap/Button";

const InfiniteListContainer = (props) => {

    const {id, data, onPageChange, searchFilter, onFilterSubmit, target, manual = false} = props;
    const {t} = useTranslations("infiniteLoader", locale)
    const isMobile = useIsMobile();

    const [virtualData, setVirtualData] = useHistoryState(id + "_data", {
        content: [],
        lastPage: -1
    });

    const previousFilter = usePrevious(searchFilter);


    //Reset On filter Change
    useEffect(
        () => {
            if (!previousFilter)
                return;

            if (!objectIsEqual(cloneWithoutKey(searchFilter, "currentPage"),
                cloneWithoutKey(previousFilter, "currentPage"))) {
                setVirtualData({
                    content: [],
                    lastPage: -1
                });
            }
        },
        // eslint-disable-next-line
        [searchFilter, previousFilter]
    );

    useEffect(
        () => {
            if (!data?.content)
                return;

            if (virtualData?.lastPage !== data?.currentPage || virtualData?.content.length === 0) {
                setVirtualData(virtualData => ({
                    ...data,
                    lastPage: data?.currentPage,
                    content: [...virtualData?.content, ...data?.content],
                }));
            }
        },
        // eslint-disable-next-line
        [data?.content]
    );


    const fetchData = useCallback(
        (force) => {

            if (!force && manual)
                return;

            if (virtualData?.lastPage === -1)
                return;

            onPageChange(data?.currentPage + 2)
        },
        [data?.currentPage, virtualData?.lastPage, onPageChange, manual]
    );


    const hasMore = useMemo(
        () => (data?.currentPage !== undefined)
            ? (data?.currentPage !== (data?.totalPages - 1) && data?.totalPages !== 0)
            : false,
        [data?.currentPage, data?.totalPages]
    );


    const loader = useMemo(
        () => {
            if (!manual)
                return <div className={"infinite-loading"}>{t('loading')}</div>;
            else {
                return <div className={"infinite-loading load-more"}>
                    <Button
                        variant="primary"
                        onClick={() => fetchData(true)}>
                        {t("load_more")}
                    </Button>
                </div>;
            }
        },
        [manual, fetchData,t]
    )


    return <InfiniteScroll
        dataLength={virtualData.content.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader}
        scrollThreshold={0.4}
        pullDownToRefresh={false}
        scrollableTarget={target ? target : (!isMobile ? "content-area" : undefined)}
        style={{
            overflow: "unset"
        }}
    >
        {React.cloneElement(props.children, {
            ...props,
            loading: (virtualData?.lastPage === -1),
            data: virtualData,
            reload: useCallback(
                () => {
                    onFilterSubmit({
                        _reload: searchFilter?._reload ? false : true
                    })
                },

                [onFilterSubmit, searchFilter?._reload]
            )
        })}

    </InfiniteScroll>


}

export default InfiniteListContainer;
