import {useTranslation} from "react-i18next";
import React, {useRef} from "react";
import classnames from "classnames";
import StatusLabelComponent from "app/AnimalLost/components/list/StatusLabelComponent";

import "app/AnimalLost/assets/card.scss";
import useOnScreen from "system/Window/useOnScreen";
import AnimalResumeComponent from "app/AnimalLost/components/view/AnimalResumeComponent";

const ExternalAnimalCardComponent = (props) => {

    const {t} = useTranslation('animal');
    const {animal} = props;

    const ref = useRef();
    const isOnScreen = useOnScreen(ref);

    return <div className={"animal-card"} ref={ref}>

        {isOnScreen
            ? <>
                <StatusLabelComponent animal={animal}/>

                <div className={classnames("animal-card-box")}>

                    <AnimalResumeComponent animal={animal}/>

                </div>

                <div className={"animal-card-photo"}>
                    {animal.photo
                        ? <img src={`data:image/jpeg;base64,${animal.photo}`} alt={"animal"}/>
                        : <div className={"no-photo"}>
                            {t('no_photo')}
                        </div>
                    }
                </div>
            </>
            : <div className={"not-on-screen"}/>
        }


    </div>
}

export default ExternalAnimalCardComponent;
