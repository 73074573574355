import React, {useCallback, useMemo} from "react";
import {getSelectedOptions, getValuesArrayFromObjectsArray} from "../utils/utils";
import locale from "../EnumDropdown/locales/locales";
import Select from "react-select";
import useTranslations from "system/Translations/UseTranslations";
import useCountryListOptions from "modules/Form/Dropdowns/CountryDropdown/useCountryList";
import OptionsSort from "modules/Form/Dropdowns/ClassDropdown/utils/OptionsSort";
import usePlaceHolder from "modules/Form/Dropdowns/ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "modules/Form/Dropdowns/ClassDropdown/utils/useLabelCallback";

import 'modules/Form/Dropdowns/CountryDropdown/assets/flags.scss';
import 'modules/Form/Dropdowns/CountryDropdown/assets/country-dropdown.scss';

const CountryDropdown = React.forwardRef((props, ref) => {

    const {t} = useTranslations('enumDropdown', locale);

    const {id = "country", value, defaultValue, isMulti, excludeList = [], onChange} = props;

    const countries = useCountryListOptions();

    const isControlled = (value !== undefined);

    const currentValue = useMemo(
        () => isControlled ? value : defaultValue,
        [isControlled, value, defaultValue]
    );

    const options = useMemo(
        () => {
            return countries.filter(el => !excludeList.includes(el.value))
                .sort(OptionsSort)
        },
        // eslint-disable-next-line
        []
    );

    const selectedOptions = useMemo(
        () => getSelectedOptions(currentValue, options, isMulti),
        [options, currentValue, isMulti]
    );


    const handleOptionFlag = useCallback(
        (country) => (
            <div className="country-option flag-entry">
                <div className={"flag " + country?.value.toLowerCase()}/>
                <div className={"label"}>{country.label}</div>
            </div>
        ),
        []
    );

    return <Select
        {...props}
        ref={ref}
        name={id}
        classNamePrefix="rs"
        className="rs"
        options={options}
        defaultValue={!isControlled ? selectedOptions : undefined}
        value={isControlled ? selectedOptions : undefined}
        isMulti={isMulti}
        placeholder={usePlaceHolder(props.placeholder,t('placeholder'))}
        noOptionsMessage={useLabelCallback(props.noOptionsMessage, t('no_results'))}
        loadingMessage={useLabelCallback(props.loadingMessage,t('loading_message'))}
        formatOptionLabel={handleOptionFlag}
        onChange={useCallback(
            (options) => {
                if (onChange)
                    onChange(getValuesArrayFromObjectsArray(options, isMulti), options)
            },
            [onChange, isMulti]
        )}

    />
});

export default CountryDropdown;
