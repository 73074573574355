import FormComponent from "app/AnimalLost/components/form/FormComponent";
import Loading from "modules/Loading/Loading";
import React from "react";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import classnames from "classnames";
import InfoAreaComponent from "app/AnimalLost/components/view/InfoAreaComponent";
import TopBanner from "layout/components/TopBanner/TopBanner";
import {useTranslation} from "react-i18next";
import AnimalResumeComponent from "app/AnimalLost/components/view/AnimalResumeComponent";
import FacebookShare from "modules/Share/FacebookShare";
import "app/AnimalLost/assets/view.scss";
import GoBack from "layout/components/TopBanner/GoBack";

const ViewAnimalComponent = props => {

    const {animal} = props;

    const {t} = useTranslation('animal');

    if (animal) {
        return <>
            <TopBanner className={"small"}>
                <GoBack/>
                <div className={"title"}>
                    {t(animal?.status)}
                </div>
            </TopBanner>

            <div className={"view-animal"}>

                <div className={"left-bar"}>

                    <div className={"photo-animal"}>
                        {animal.photo
                            ? <img src={`data:image/jpeg;base64,${animal.photo}`} alt={"animal"}/>
                            : <div className={"no-photo-area"}>
                                <div className={"no-photo"}>
                                    {t('no_photo')}
                                </div>
                            </div>
                        }
                    </div>

                    <div className={"box-status"}>

                        <AnimalResumeComponent animal={animal}/>
                        <InfoAreaComponent animal={animal}/>
                        <FacebookShare label={t('share')}/>
                    </div>

                </div>

                <div className={classnames("right-content", {"lost": animal?.status === AnimalStatus.LOST})}>
                    <FormComponent animal={animal} readOnly={true}/>
                </div>


            </div>
        </>
    } else {
        return <Loading visible={true}/>
    }


}

export default ViewAnimalComponent;
