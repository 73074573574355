import {Outlet} from "react-router-dom";

import "layout/assets/css/fonts.scss";
import "layout/assets/css/layout/layout.scss";
import BottomBanner from "layout/components/BottomBanner/BottomBanner";

const MainLayout = props => {

    return <div className={"main-layout"}>


        <Outlet/>

        <BottomBanner/>
    </div>
}

export default MainLayout;
