import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"

const BreedDropdown = (props) => {

    const {specieIds} = props;

    return <GenericRemoteClassDropdown
        {...props}
        remotePath={"/breeds/search-breeds"}
        universalSearch={"name"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 50,
            specieIds: specieIds,
        }), [specieIds])}
    />

}

export default BreedDropdown;
