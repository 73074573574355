import React from "react";
import EnumDropdown from "../EnumDropdown/EnumDropdown";

const BooleanDropdown = (props) => {

    const {isClearable = false} = props;

    return <EnumDropdown
        {...props}
        options={{
            TRUE: true,
            FALSE: false,
        }}
        isSearchable={false}
        isClearable={isClearable}
    />
}


export default BooleanDropdown;
