import {isMatch} from "date-fns";
import {isString} from "system/Utils/isString";

const filterDatePattern = "^[0-9]{1,2}(-)?([0-9]{1,2})?(-)?([0-9]{1,4})?$";
const isValidDay = (day, month) => (!day || (month === undefined && day === "0")) ? true : isMatch(day, 'dd');
const isValidMonth = (day, month, year) => (!month || (year === undefined && month === "0"))  ? true : isMatch(day + "/" + month, 'dd/MM');

const pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const addBars = (value) => {
    if (value.length > 2 && value.indexOf('-') === -1) {
        return [value.slice(0, 2), "-", value.slice(2)].join('');
    } else if (value.length > 5 && value.split('-').length === 2) {
        return [value.slice(0, 5), "-", value.slice(5)].join('');
    }
    return value;
}

const rebuildDate = (day, month, year) => {
    if (year !== undefined) {
        return pad(day, 2, 0) + "-" + pad(month, 2, 0) + "-" + year;
    } else if (month !== undefined) {
        return pad(day, 2, 0) + "-" + month;
    } else
        return day;
}

const filterDate = (iVal) => {

    if (!isString(iVal)) {
        return false;
    }

    const value = iVal.trim(iVal);
    const [day, month, year] = addBars(value).split('-');

    if (value.match(filterDatePattern) && isValidDay(day, month) && isValidMonth(day, month, year)) {
        return rebuildDate(day, month, year);
    }

    return (value.length === 0) ? iVal : false;

}

export {filterDate};
