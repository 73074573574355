export const pt = {

    select_status_msg: "Por favor indique se o animal está desaparecido ou se foi encontrado",
    lost_set_lost: "Este animal está desaparecido",
    lost_set_lost_msg: "Neste momento não se sabe do paradeiro do animal",

    lost_set_found: "Este animal foi encontrado por terceiros",
    lost_set_found_msg: "Este animal foi recolhido e identificado.",

    general: "Dados Gerais",

    name: "Nome do Animal",
    no_name: "Sem nome",
    no_photo: "Sem foto",
    name_ph: "Preencha caso saiba o nome do animal",
    transponder: "Transponder",
    is_cross_breed: "Tem Cruzamento?",
    cross_breed: "Raça Cruzada",
    breed: "Raça",
    color: "Cor",
    color_ph: "A cor do animal (ex. Preto)",
    gender: "Sexo",
    gender_ph: "Selecione uma opção",
    date_ph: "Data no formato (dd-mm-yyyy)",

    transponder_ph: "Transponder",
    lost_local: "Local da Ocorrência",
    lost_animal_situation: "Situação do Animal",
    lost_animal_situation_ph: "Escolha a situação do animal",
    event_country: "País da Ocorrência",
    occurrence_date: "Data da Ocorrência",
    participant_data: "Informação do Participante",
    participant_name: "Nome",
    participant_name_alt: "Nome do Participante",
    participant_contact: "Contacto Telefónico",
    participant_email: "Email",
    email_ph: "Escreva um Email",
    participant_name_ph: "O nome do participante",
    local_ph: "Acrescente mais informação sobre o local da ocorrência",
    allow_advertising: "Permitir publicação pública",
    allow_advertising_msg: "Declaro que autorizo a divulgação pública da informação deste processo de recuperação de animal perdido, incluindo a minha identificação e os meus dados de contacto, na forma de anúncio, com acesso público, para publicitar a situação relativa ao animal e permitir o contacto para sua recuperação." +
        "Esta informação ficará disponível em www.siac.vet, podendo, a qualquer momento, solicitar que deixe de estar disponível publicamente.",
    animal_photo: "Fotografia do Animal",
    observations: "Observações",
    specie: "Espécie",
    place: "Local",

    upload_lost_file_label: "Caso deseje, pode fazer o upload de uma fotografia do animal ou carregar uma fotografia ou animal. <br>Em caso afirmativo, escolha ou arraste uma fotografia para esta caixa.",

    MALE: "Masculino",
    FEMALE: "Feminino",

    confirm: "Submeter Pedido",
    no_results: "Sem resultados para apresentar",

    INVALID_TRANSPONDER: "O transponder não é válido",
    MISSING_TRANSPONDER: "Transponder em falta",
    MISSING_GENDER: "Sexo do Animal em falta",
    MISSING_BREED: "Raça em falta",
    MISSING_COLOR: "Cor em falta",
    INVALID_DATE: "Por favor indique uma data válida",
    INVALID_EMAIL: "Por favor indique um email válido",

    INVALID_SITUATION: "Deve indicar a situação atual do animal",
    MISSING_LOCAL: "Por favor indique o local",
    MISSING_DATE: "Por favor indique a data",
    MISSING_PARTICIPANT_NAME: "Por favor indique o nome do participante",
    MISSING_PARTICIPANT_CONTACT: "Por favor indique o contacto do participante",
    MISSING_COUNTY: "Indique o concelho",
    INVALID_DISTRICT: "Indique um distrito",

    //Animal Situations
    SHELTER: "Acolhimento",
    STREET: "Rua",
    KENNEL: "Canil Municipal",
    CAMV: "CAMV",
    WITH_PARTICIPANT: "Com o participante",
    OTHER: "Outra",

    success_found_message_title: "O processo foi remetido para os serviços do SIAC com sucesso.",
    success_found_message: "Para que o anúncio seja publicado na base de dados e no SIAC PERDIDOS, caso tenha assinalado a sua divulgação pública, terá que ser aprovado pelos Serviços do SIAC. Assim que for aprovado o anúncio será publicado.",

    success_lost_message_title: "O processo foi remetido para os serviços do SIAC com sucesso.",
    success_lost_message: "Para que o anúncio seja publicado na base de dados e no SIAC PERDIDOS, caso tenha assinalado a sua divulgação pública, terá que ser aprovado pelos Serviços do SIAC. Assim que for aprovado o anúncio será publicado.<br/>" +
        "<br/>Em momento algum o número de transponder é publicado no processo, contudo através da introdução dessa numeração na pesquisa é possível chegar ao anúncio.<br/>" +
        "<br/>Assim que recuperar o seu animal deve contactar o SIAC para que o processo de animal perdido seja removido.",



    form_has_errors: "Existem erros no formulário. Por favor verifique os campos assinalados.",
    form_has_errors_title: "Não foi possível submeter o formulário",

    CANNOT_REGISTER_LOST_ANIMAL: "Não é possível realizar o pedido para o transponder indicado. Por favor entre em contacto com os serviços SIAC.",

    /** Lost */
    close: "Fechar",
    event_date: "Data da Ocorrência",
    county: "Concelho",
    district: "Distrito",
    parish: "Freguesia",
    LOST: "Animal Desaparecido",
    FOUND: "Animal Encontrado",

    //Age
    YEAR_one: "Ano",
    YEAR_other: "Anos",

    MONTH_one: "Mês",
    MONTH_other: "Meses",

    DAY_one: "Dia",
    DAY_other: "Dias",

    //filter
    status: "Estado",
    status_ph: "Escolha um estado para filtrar",

    more_information: "Caso seja o titular deste animal deverá entrar em contacto com os serviços do SIAC",
    animal_lost_not_public: "O transponder pesquisado pertence a um animal que está dado como perdido (desaparecido ou encontrado) mas que <strong>não se encontra com o registo público</strong>." +
        "<br/>Para obter mais informações deverá entrar em contacto com os serviços SIAC.",


    lost_list_title: "LISTA DE ANIMAIS DESAPARECIDOS E ENCONTRADOS",
    lost_list_title_msg: "Confirme na lista seguinte se algum dos animais corresponde ao que procura ou encontrou",


    banner_main_msg:  "Se o seu animal de companhia desapareceu, ou caso tenha encontrado um animal que pode pertencer a terceiros,é fundamental que reporte a situação.\n\n",
    banner_sec_msg: "Neste site encontrará dicas úteis sobre ações a serem tomadas em caso de perda de um animal de companhia ou informações sobre o que pode fazer quando se encontra um animal.\n.\n",
    share: "Partilhar",
    register: "Registar",

    lost_header: "Formulário para abertura de um processo de animal desaparecido do seu titular",
    lost_header_msg: "Por favor, preencha o formulário disponível abaixo para que a informação de animal desaparecido seja registada no SIAC.",

    found_header: "Formulário para abertura de um processo de animal encontrado",
    found_header_msg: "Por favor, preencha o formulário disponível abaixo para que a informação de animal encontrado seja registada no SIAC.",

    from_creation_date: "Data de registo desde",
    to_creation_date: "Data de registo até",
    from_occurrence_date: "Data de ocorrência desde",
    to_occurrence_date: "Data de ocorrência até",

};

export default pt;
