import Form from "react-bootstrap/Form";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import locale from "../locales/locales";
import {Collapse} from "react-bootstrap";
import SearchFilterBadges from "./SearchFilterBadges";
import {getFormDataWithLabels, getFormDataWithoutParameter} from "modules/SearchArea/utils/filter";
import {objectIsEqual} from "system/Objects/Objects";
import {getFormData} from "system/Forms/FormHandling";
import useTranslations from "system/Translations/UseTranslations";
import '../assets/css/search-filter.scss'


const SearchFilter = (props) => {

    const {onSubmit = () => void 0, submitLabel, isOpen, onClose = () => void 0, children} = props;

    const form = useRef(null);
    const {t} = useTranslations('searchArea', locale);

    const [activeFilters, setActiveFilters] = useState(null);
    const [submitPending, setSubmitPending] = useState(false);

    //Get default filters
    useEffect(() => {
        let cf = getFormDataWithLabels(form.current);
        if (!objectIsEqual(cf, activeFilters) && !submitPending) {
            setActiveFilters(cf);
        }
    }, [setActiveFilters, activeFilters, children, submitPending]);

    const handleFormSubmit = useCallback(() => {
        onSubmit(getFormData(form.current, true));
        setSubmitPending(false);
        setActiveFilters(getFormDataWithLabels(form.current));
        onClose();
    }, [onClose, onSubmit, form]);

    const handleRemoveFilter = useCallback((key, id) => {
        onSubmit(getFormDataWithoutParameter(form.current, key, id));
        setSubmitPending(false);
    }, [onSubmit, form]);


    return <>
        <Collapse in={isOpen}>
            <div className="search-area-filters" style={!isOpen ? {display: "none"} : null}>
                <Form
                    onClick={() => setSubmitPending(true)} ref={form}
                >
                    {children}
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={handleFormSubmit}>
                                {submitLabel ? submitLabel : t('filter_submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Collapse>

        <SearchFilterBadges visible={!isOpen} filters={activeFilters} onRemove={handleRemoveFilter}/>
    </>

}

export default SearchFilter;
