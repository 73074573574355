//Get Value array from an array of objects

import getAsArray from "../../../../system/Arrays/getArray";

const getValuesArrayFromObjectsArray = (arrayObjects, isMulti, objectParameter = 'value') => {
    if (!arrayObjects)
        return isMulti ? [] : null;
    else if (Array.isArray(arrayObjects))
        return arrayObjects.map(option => option[objectParameter]);
    else
        return isMulti ? [arrayObjects[objectParameter]] : arrayObjects[objectParameter];
}

const getStringValuesArrayFromObjectsArray = (arrayObjects, isMulti, objectParameter) => {

    const r = parseParameterInString(getValuesArrayFromObjectsArray(arrayObjects, isMulti, objectParameter));
    if (r)
        return r;
    else return null;

}

const parseParameterInString = (arr) => {
    if (Array.isArray(arr)) {
        return arr.map(String);
    } else if (arr !== null && arr !== undefined) {
        return String(arr);
    } else return undefined;
}

const getSelectedOptions = (value, options, isMulti) => {

    if (!options) {
        return null;
    }

    if (value === null || (isMulti && getAsArray(value).length === 0))
        return null;
    else if (!isMulti)
        return options.find(element => String(value) === String(element.value));
    else {
        return options.filter(element =>
            getAsArray(value).map(String).includes(element.value)
        );
    }
}


export {
    getValuesArrayFromObjectsArray,
    getStringValuesArrayFromObjectsArray,
    parseParameterInString,
    getSelectedOptions
};
