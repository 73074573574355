import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {filterDate} from "modules/Form/DatePicker/utils/DateValidators";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const dateFormat = "DD-MM-YYYY";

function validateDateString(dateString) {
    try {
        return (dayjs(dateString, dateFormat).format(dateFormat) === dateString);
    } catch {
        return false;
    }
}

const getDateObject = (date) => {

    if (date && filterDate(date))
        return dayjs(date, dateFormat).toDate();
    return undefined;
}

//Returns date in dateFormat as string
const getDateString = (dateTime) => {
    if (dateTime) {
        return dayjs(dateTime).format(dateFormat);
    } else {
        return dateTime;
    }
}


const getUnixTimestamp = (dateString, timezone) => dayjs.tz(dateString, dateFormat, timezone).valueOf();

export {
    getDateString,
    getDateObject,
    validateDateString,
    getUnixTimestamp
}
