const GenderType = {
    MALE: "MALE",
    FEMALE: "FEMALE",
};

const AnimalStatus = {
    FOUND: "FOUND",
    LOST: "LOST",

};

const AnimalFoundStatus = {
    SHELTER: "SHELTER",
    STREET: "STREET",
    KENNEL: "KENNEL",
    CAMV: "CAMV",
    WITH_PARTICIPANT: "WITH_PARTICIPANT",
    OTHER: "OTHER",
};

export {GenderType, AnimalStatus, AnimalFoundStatus};
