import React, {useCallback, useMemo} from "react";
import locale from "./locales/locales";
import Select from "react-select";
import {getSelectedOptions, getValuesArrayFromObjectsArray} from "../utils/utils";
import useTranslations from "../../../../system/Translations/UseTranslations";
import usePlaceHolder from "../ClassDropdown/utils/usePlaceHolder";
import useLabelCallback from "../ClassDropdown/utils/useLabelCallback";


const EnumDropdown = React.forwardRef((props, ref) => {

    const {t: te, ready: tr} = useTranslations('enumDropdown', locale);

    const {
        defaultValue,
        value,
        t = te,
        translationReady = tr,
        options: opts,
        isMulti,
        isClearable = true,
        onChange = () => void 0,
    } = props


    const isControlled = (value !== undefined);

    const currentValue = useMemo(
        () => isControlled ? value : defaultValue,
        [isControlled, value, defaultValue]
    );

    //Construct options based on object {key: value}
    const options = useMemo(() =>
            Object.entries(opts).map((object) => ({
                label: t(object[0]),
                value: object[1]
            })),
        [opts, t]);

    const selectedOptions = useMemo(
        () => getSelectedOptions(currentValue, options, isMulti),
        [options, currentValue, isMulti]
    );


    const handleChange = useCallback(
        (options) => onChange(getValuesArrayFromObjectsArray(options, isMulti), options),
        [onChange, isMulti]
    );

    const handlePlaceHolder = usePlaceHolder(props.placeholder, t('placeholder'));
    const noOptionsMessage = useLabelCallback(props.noOptionsMessage, t('no_results'));
    const loadingMessage = useLabelCallback(props.loadingMessage, t('loading_message'));

    if (!translationReady) {
        return <></>;
    }

    return <Select
        {...props}
        ref={ref}
        name={props.id}
        classNamePrefix="rs"
        className="rs"
        options={options}
        defaultValue={!isControlled ? selectedOptions : undefined}
        value={isControlled ? selectedOptions : undefined}
        onChange={handleChange}
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder={handlePlaceHolder}
        noOptionsMessage={noOptionsMessage}
        loadingMessage={loadingMessage}
    />
});


export default EnumDropdown;
