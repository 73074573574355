import useWindowSize from "./WindowSize";

const useIsMobile = () => {

    const {width} = useWindowSize();
    return width <= 992;
}


const NotInMobile = props => {

    const isMobile = useIsMobile();

    if (!isMobile)
        return props.children;

    return <></>;

}

export {NotInMobile, useIsMobile};
