import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {BrowserRouter} from "react-router-dom";
import './configs/i18n/i18n';
import './layout/assets/css/main.scss';
import './layout/assets/css/react-dropdown.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';


const emotionalNonce = process.env.EMOTIONAL_NONCE;

const styleCache = createCache({
    key: 'siac-css',
    nonce: emotionalNonce,
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <CacheProvider value={styleCache}>
            <App/>
        </CacheProvider>
    </BrowserRouter>,
);



