import useTranslations from "system/Translations/UseTranslations";
import locale from "locale/locales";
import logo from "layout/assets/images/logo-branco.png";
import {NavLink} from "react-router-dom";
import './TopBanner.scss';
import classnames from "classnames";
import {useIsMobile} from "system/Window/Mobile";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";

const TopBanner = props => {

    const {t} = useTranslations("global", locale)
    const {className} = props;

    const isMobile = useIsMobile();
    const [isMenuVisible, setMenuVisible] = useState(false);

    return <div className={classnames("top-banner", className)}>

        <div className={classnames("menu")}>

            <img src={logo} className="logo" alt={"logo"}/>

            {isMobile &&
            <FontAwesomeIcon icon={faBars} className={"burger"} onClick={() => setMenuVisible(!isMenuVisible)}/>
            }

            {(!isMobile || isMenuVisible) &&
            <div className={"links"}>

                <NavLink to={"/"} className={({isActive}) => isActive ? "active" : undefined}>
                    {t("lost_list")}
                </NavLink>

                <NavLink to={"/form/lost"} className={({isActive}) => isActive ? "active" : undefined}>
                    {t("register_lost")}
                </NavLink>

                <NavLink to={"/form/found"} className={({isActive}) => isActive ? "active" : undefined}>
                    {t("register_found")}
                </NavLink>


                <NavLink to={"/como-funciona/"} className={({isActive}) => isActive ? "active" : undefined}>
                    {t("how_works")}
                </NavLink>

            </div>
            }
        </div>

        <div className={"banner-body"}>
            {props.children}
        </div>


    </div>
}

export default TopBanner;
