import React from 'react'
import '../assets/box.scss'
import '../assets/box-responsive.scss'
import classnames from "classnames";
import {cloneWithoutKey} from "../../../system/Objects/Objects";

const BoxOptions = (props) => {

    const { children , visible = true, className} = props;

    if (!visible) {
        return null;
    }

    return <div
        {...cloneWithoutKey(props,"visible")}
        className={classnames("box-options",className)}>

        {children}
    </div>
}

export default BoxOptions;
