import React, {useEffect, useState} from "react";
import useScrollToError from "./utils/scrollToError";
import {Button, Modal} from "react-bootstrap";
import ModalButtons from "modules/Modal/Buttons/ModalButtons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./errors.scss";

const GenericFormError = ({genericError, errors, t, id = 'formError'}) => {

    //const {scrollToTitle} = useSetTitle();
    const scrollToError = useScrollToError();
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length > 0 && scrollToError) {
            setVisible(true);
            setTimeout(function () {
                scrollToError();
            }, 400);

        }
    }, [errors, scrollToError, setVisible]);


    useEffect(() => {
        if (scrollToError && genericError) {
            setVisible(true);
            setTimeout(function () {
                scrollToError();
            }, 400);

        }
    }, [genericError, scrollToError, setVisible]);

    if (Object.keys(errors).length > 0 || genericError) {
        return <Modal
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className={"error"}
            show={isVisible}
            onHide={() => setVisible(false)}
        >

            <Modal.Body>
                <div className={"error-message"}>

                    <div className={"icon-container"}>
                        <FontAwesomeIcon icon={faTimes} className={"icon"}/>
                    </div>

                    <div className={"main-text"}>
                        {t('form_has_errors_title')}
                    </div>

                    {Object.keys(errors).length > 0 ? t('form_has_errors') : t(genericError)}
                </div>
            </Modal.Body>

            <ModalButtons>
                <Button variant={"danger"} onClick={() => setVisible(false)}>
                    {t('close')}
                </Button>
            </ModalButtons>
        </Modal>
    } else return <></>

}

export default GenericFormError;
