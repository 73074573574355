import {useEffect} from "react";

export const useClickedOutsideEffect = (ref, fnc) => {

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                fnc(e)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[ref,fnc]);

}
