import TopBanner from "layout/components/TopBanner/TopBanner";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/Pages/locales/locales";
import "app/Pages/assets/css/how-works.scss";
import ImageLost from 'app/Pages/assets/images/animal-perdido.png';
import ImageFound from 'app/Pages/assets/images/animal-encontrado.png';
import BoxStep from "app/Pages/components/BoxStep";
import Box from "modules/Box/Box";
import GoBack from "layout/components/TopBanner/GoBack";
import React from "react";

const HowItWorks = props => {

    const {t} = useTranslations("pages", locale);

    return <>
        <TopBanner className={"small dog"}>
            <GoBack/>
            <div className={"main-text"}>
                {t('how_works_title')}
            </div>

            <div className={"sec-text"}>
                {t('how_works_sub_title')}
            </div>

        </TopBanner>

        <div className={"how-works"}>

            <Box>
                <div className={"sub-title"}>
                    ANIMAL DESAPARECIDO DO SEU TITULAR
                </div>

                Quando perde um animal o seu titular tem um prazo de 15 dias para notificar o seu desaparecimento à base
                de
                dados SIAC.
                <br/><br/>
                Nota: Durante o preenchimento do formulário disponível abaixo poderá selecionar a opção de permitir que
                o
                registo do desaparecimento do seu animal seja publicado e fique visível aqui.

                <img src={ImageLost} className={"image-lost"} alt={"Informação Perdido - Diagrama"}/>


                <div className={"steps"}>
                    <BoxStep title={"Verificar"} step={1}>
                        Verificar o número de transponder/microchip que o animal de companhia tem implantado.
                        Normalmente o número é composto por 15 dígitos numéricos.
                    </BoxStep>

                    <BoxStep title={"Informar"} step={2}>
                        Informar o Médico Veterinário ou o SIAC que o animal encontra-se desaparecido. Deve também
                        informar o local do desaparecimento e a data.
                    </BoxStep>

                    <BoxStep title={"Confirmar"} step={3}>
                        Confirmar que o processo de animal perdido encontra-se aberto no SIAC. Os dados do titular e do
                        animal devem estar atualizados. Pode atualizar os seus dados aqui.
                    </BoxStep>

                    <BoxStep title={"Contactar"} step={4}>
                        Assim que o animal regressar ao seu titular este deve informar o SIAC ou o Médico Veterinário
                        para que o processo seja encerrado.
                    </BoxStep>

                </div>
            </Box>


            <Box>
                <div className={"sub-title"}>
                    ANIMAL ENCONTRADO POR TERCEIRO
                </div>

                Quando um animal é encontrado por terceiros existem vários procedimentos importantes a ter em conta.

                <img src={ImageFound} className={"image-lost"} alt={"Informação Encontrado - Diagrama"}/>


                <div className={"steps"}>
                    <BoxStep title={"Verificar"} step={1}>
                        Verificar se o animal tem alguma identificação. O animal poderá ter uma chapa com a
                        identificação do
                        titular ou com o número de microchip do animal.
                    </BoxStep>

                    <BoxStep title={"Informar"} step={2}>
                        Dirigir-se com o animal a um Centro de Atendimento Médico-Veterinário ou, contactar as
                        autoridades municipais para que seja possível confirmar a identificação do animal.
                    </BoxStep>

                    <BoxStep title={"Confirmar"} step={3}>
                        O Médico Veterinário deverá verificar se existe registo na base de dados SIAC e contactar o
                        titular do animal para que este o possa recolher.
                    </BoxStep>

                    <BoxStep title={"Contactar"} step={4}>
                        O titular do animal de companhia deverá reportar ao SIAC que o animal já se encontra novamente
                        consigo. Poderá também preencher o formulário aqui disponível.
                    </BoxStep>

                </div>
            </Box>


        </div>
    </>


}

export default HowItWorks
