import "app/Pages/assets/css/box-step.scss";

const BoxStep = props => {

    const {title, children, step} = props

    return <div className={"box-step"}>

        <div className={"step"}>
            {step}
        </div>
        <div className={"step-title"}>
            {title}
        </div>

        <div className={"step-content"}>
            {children}
        </div>

    </div>

}

export default BoxStep;
