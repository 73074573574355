import React, {Suspense} from "react";
import appConfig from "configs/config";
import LostForm from 'app/AnimalLost/Form'
import LostList from 'app/AnimalLost/List'
import {RestfulProvider} from "system/Rest/RestfulProvider";
import {Route, Routes} from "react-router-dom";
import MainLayout from "layout/MainLayout";
import AnimalLost from "app/AnimalLost/AnimalLost";
import {LoadingStateProvider} from "modules/Loading/LoadingStateContext";
import Loading from "modules/Loading/Loading";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import HowItWorks from "app/Pages/HowItWorks";

function App() {

    return (
        <RestfulProvider base={appConfig.api.base}>
            <div className="App">
                <LoadingStateProvider>
                    <Suspense fallback={<Loading visible={true}/>}>
                        <Routes>
                            <Route path="/" element={<MainLayout/>}>
                                <Route index element={<LostList/>}/>
                                <Route path="como-funciona" element={<HowItWorks/>}/>
                                <Route path="form">
                                    <Route path="found" element={<LostForm type={AnimalStatus.FOUND}/>}/>
                                    <Route path="lost" element={<LostForm type={AnimalStatus.LOST}/>}/>
                                </Route>
                                <Route path=":id" element={<AnimalLost/>}/>
                            </Route>
                        </Routes>
                    </Suspense>
                </LoadingStateProvider>
            </div>
        </RestfulProvider>
    );
}

export default App;
