import React, {useMemo} from 'react'
import GenericRemoteClassDropdown from "../GenericRemoteClassDropdown"

const SpecieDropdown = (props) => {

    return <GenericRemoteClassDropdown
        {...props}
        remotePath={"/breeds/search-species"}
        universalSearch={"name"}
        searchFilter={useMemo(() => ({
            sortProperty: 'name',
            sortDirection: 'ASC',
            currentPage: 0,
            itemsPerPage: 50,
        }),[])}
    />

}

export default SpecieDropdown;
