import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import locale from "../locales/locales";

import '../assets/css/search-area.scss'
import '../assets/css/search-area-responsive.scss'
import {setLangConfig} from "../../../configs/i18n/i18n";
import FormControl from "../../Form/Control/FormControl";

const SearchControl = (props) => {

    const {value, searchControl, ...otherProps} = props;

    const {t} = useTranslation('searchArea');
    useEffect(() => setLangConfig('searchArea', locale), []);

    return <div className={"search-control"}>
        <InputGroup className="mb-2 mr-sm-2">
            <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch}/>
            </InputGroup.Text>
            {searchControl
                ? searchControl(props)
                : <FormControl {...otherProps} placeholder={t('search')} type="text" value={value}/>
            }
        </InputGroup>
    </div>;

}

export default SearchControl;
