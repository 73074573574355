import {Trans} from "react-i18next";
import {ReactComponent as Upload} from "./assets/images/upload.svg";
import Button from "react-bootstrap/Button";
import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import locale from "./locale/locales";
import useTranslations from "system/Translations/UseTranslations";
import "./assets/css/dropzone.scss"


const DragDropFile = React.forwardRef((props, ref) => {

    const {acceptTypes, text, onFileUploaded, maxFiles = 1, maxSize = 5242880, onFocus, children, disabledClick = false,handleErrors} = props;
    const {t} = useTranslations("dragDrop", locale);

    const [errors, setErrors] = useState(null)

    useEffect(
        () => {
            handleErrors(errors);
        },
        [errors, handleErrors]
    )

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: acceptTypes,
        maxFiles: maxFiles,
        maxSize: maxSize,
        onDrop: useCallback(e => {
            setErrors(null);
                onFileUploaded(e);
                if (onFocus)
                    onFocus();
            },
            [onFileUploaded, onFocus, setErrors]
        ),
        onDropRejected: useCallback(
            (rejectedFiles) =>
                rejectedFiles.map(({file, errors}) => errors.map(e => setErrors(errors =>
                ([...errors, {
                    title: t(e.code.replace(/-/g, "_") + "_title"),
                    message: t(e.code.replace(/-/g, "_"), {file: file.path}),
                }])))),
            [setErrors, t]
        ),
    });

    const {ref: refInternal, ...inputProps} = getInputProps();

    return <div className={"dropzone"}>
        <section className={isDragActive ? "drop-container active" : "drop-container"}>
            <div {...getRootProps({
                className: 'dropzone-area',
                onClick: (e) => {
                    if (disabledClick)
                        e.stopPropagation()
                },
            })}>
                <input {...inputProps} ref={ref ? ref : refInternal}/>
                <Trans>
                    <div className={"drop-now"}>
                        {t('drop_now')}
                    </div>

                    {children
                        ? children
                        : <div className={"dropzone-text"}>

                            <div>
                                <Upload/>
                            </div>

                            <div>
                                {text}
                            </div>

                            <div>
                                <Button variant="primary">
                                    {t('choose_file')}
                                </Button>
                            </div>
                        </div>
                    }
                </Trans>

            </div>


        </section>

    </div>

});

export default DragDropFile;
