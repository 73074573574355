import classnames from "classnames";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import useTranslations from "system/Translations/UseTranslations";
import locale from "app/AnimalLost/locales/locales";
import "app/AnimalLost/assets/info-area.scss";

const InfoAreaComponent = props => {

    const {t} = useTranslations("animal", locale);
    const {animal} = props;


    return <div className={classnames("info-area",{"lost": animal?.status === AnimalStatus.LOST})}>

        <div className={"title"}>
            {t(animal?.status)}
        </div>

        {animal?.status === AnimalStatus.FOUND &&
        <>
            <div className={"sub-title"}>
                Se este animal é seu, por favor, entre em contacto com os serviços do SIAC.
            </div>
        </>
        }


        {animal?.status === AnimalStatus.LOST &&
        <>
            <div className={"sub-title"}>
                Se encontrou este animal, por favor, contacte o participante do anúncio ou os serviços do SIAC.
            </div>
        </>
        }



    </div>
}

export default InfoAreaComponent;
