import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React, {useCallback} from "react";
import BooleanDropdown from "modules/Form/Dropdowns/BooleanDropdown/BooleanDropdown";
import {parseBoolean} from "system/Utils/parseBoolean";
import FormGroup from "modules/Form/Group/FormGroup";
import SpecieDropdown from "modules/Form/Dropdowns/ClassDropdown/components/SpecieDropdown";
import {getOrEmpty, getOrNull} from "system/Objects/ObjectParameters";
import BreedDropdown from "modules/Form/Dropdowns/ClassDropdown/components/BreedDropdown";
import EnumDropdown from "modules/Form/Dropdowns/EnumDropdown/EnumDropdown";
import {GenderType} from "app/AnimalLost/utils/types";
import FormControl from "modules/Form/Control/FormControl";


const FormNotRegisteredComponent = props => {

    const {t, errors, readOnly, lostInfo, setLostInfo} = props;

    const isCrossBreed = (lostInfo?.isCrossBreed || lostInfo?.crossBreed !== undefined);

    const handleSpecieChange = useCallback(
        (val) => setLostInfo(lostInfo => ({
            ...lostInfo,
            specieId: val,
            breedId: undefined
        })),
        [setLostInfo]
    );

    const handleBreedChange = useCallback(
        (val) => setLostInfo(lostInfo => ({
            ...lostInfo,
            breedId: val,
        })),
        [setLostInfo]
    )

    return <>

        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['name']} mandatory={false}>
                <Form.Label>{t('name')}</Form.Label>

                <FormControl
                    type="text"
                    placeholder={t('name_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(lostInfo?.name)}
                    onChange={useCallback(
                        (e) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            name: e.target.value,
                        })),
                        [setLostInfo]
                    )}
                />
                <Form.Text className="sub-error">
                    {t(errors['name'])}
                </Form.Text>
            </FormGroup>
        </Col>


        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['breedId']} mandatory={true}>
                <Form.Label>{t('specie')}</Form.Label>
                {!readOnly
                    ? <SpecieDropdown
                        isMulti={false}
                        value={getOrNull(lostInfo?.specieId)}
                        isDisabled={readOnly}
                        defaultSelectedOption={lostInfo?.breed?.specie}
                        onChange={handleSpecieChange}
                    />
                    : <FormControl type="text" disabled={true} value={getOrNull(lostInfo?.specie)}/>
                }

                <Form.Text className="sub-error">
                    {t(errors['specieId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['breedId']} mandatory={true}>
                <Form.Label>{t('breed')}</Form.Label>

                {!readOnly
                    ? <BreedDropdown
                        isMulti={false}
                        value={getOrNull(lostInfo?.breedId)}
                        specieIds={lostInfo?.specieId}
                        isDisabled={readOnly || !lostInfo?.specieId}
                        defaultSelectedOption={lostInfo?.breed}
                        onChange={handleBreedChange}
                    />
                    : <FormControl type="text" disabled={true} value={getOrNull(lostInfo?.breed)}/>
                }

                <Form.Text className="sub-error">
                    {t(errors['breedId'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['isCrossBreed']} mandatory={true}>
                <Form.Label>{t('is_cross_breed')}</Form.Label>

                <BooleanDropdown
                    isClearable={false}
                    isDisabled={readOnly}
                    value={parseBoolean(isCrossBreed, false)}
                    onChange={useCallback((val) => setLostInfo(lostInfo => ({
                        ...lostInfo,
                        isCrossBreed: val,
                        crossBreedId: val ? lostInfo?.crossBreedId : null,
                    })), [setLostInfo])}
                />
            </FormGroup>
        </Col>

        {isCrossBreed &&
        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['crossBreedId']} mandatory={true}>
                <Form.Label>{t('cross_breed')}</Form.Label>
                {!readOnly
                    ? <BreedDropdown
                        isMulti={false}
                        value={getOrNull(lostInfo?.crossBreedId)}
                        specieIds={lostInfo?.specieId}
                        defaultSelectedOption={lostInfo?.crossBreed}
                        isDisabled={readOnly || !lostInfo?.specieId}
                        onChange={(breedId) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            crossBreedId: breedId,
                        }))}
                    />
                    : <FormControl type="text" disabled={true} value={getOrNull(lostInfo?.crossBreed)}/>
                }

                <Form.Text className="sub-error">
                    {t(errors['crossBreedId'])}
                </Form.Text>
            </FormGroup>
        </Col>
        }


        <Col sm={12} md={6} lg={6}>
            <FormGroup error={errors['gender']} mandatory={true}>
                <Form.Label>{t('gender')}</Form.Label>
                <EnumDropdown
                    t={t}
                    options={GenderType}
                    isDisabled={readOnly}
                    value={getOrNull(lostInfo?.gender)}
                    placeholder={t('gender_ph')}
                    onChange={useCallback(
                        (val) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            gender: val,
                        })), [setLostInfo]
                    )}
                />

                <Form.Text className="sub-error">
                    {t(errors['gender'])}
                </Form.Text>
            </FormGroup>
        </Col>

        <Col sm={12} md={6} xl={6}>
            <FormGroup error={errors['color']} mandatory={true}>
                <Form.Label>{t('color')}</Form.Label>
                <FormControl
                    type="text"
                    placeholder={t('color_ph')}
                    disabled={readOnly}
                    value={getOrEmpty(lostInfo?.color)}
                    onChange={useCallback(
                        (e) => setLostInfo(lostInfo => ({
                            ...lostInfo,
                            color: e.target.value,
                        })),
                        [setLostInfo]
                    )}

                />
                <Form.Text className="sub-error">
                    {t(errors['color'])}
                </Form.Text>
            </FormGroup>
        </Col>


    </>
}

export default FormNotRegisteredComponent;
