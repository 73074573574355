import locale from "./locales/locales";
import ListComponent from "app/AnimalLost/components/list/ListComponent";
import GenericListContainer from "modules/GenericTableContainer/GenericListContainer";
import {useMemo} from "react";

const List = (props) => {

    return <GenericListContainer
        {...props}
        module="animal"
        locale={locale}
        searchPath={"/lost-animals/"}
        queryParameters={useMemo(() => ({
            currentPage: 0,
            itemsPerPage: 10,
            sortProperty: "creationTimestamp",
            sortDirection: "DESC",
        }),[])}>

        <ListComponent/>
    </GenericListContainer>

}

export default List;
