import React, {useCallback, useEffect, useState} from "react";
import classnames from "classnames";
import {isTransponderValid} from "./utils/ValidateTransponder";
import TransponderFormComponent from "./components/TransponderFormComponent";
import "./transponder.scss";

const TransponderControl = React.forwardRef((props, ref) => {

    const {value, defaultValue, className, onValid, onInvalid, disabled, onlyNumeric = false,  ...otherProps} = props;

    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [isValid, setValid] = useState(false);


    useEffect(
        () => {
            setCurrentValue(value);
            setValid(isTransponderValid(value));
        },
        [value]
    );


    return <div className={classnames("input-wrapper transponder-input", className, {
        "valid": isValid,
        "invalid": !isValid,
        "exceeded": (currentValue?.length > 15),
    })}>

        <TransponderFormComponent
            {...otherProps}
            ref={ref}
            onChange={useCallback(
                (t = "") => {

                    let transponder = t.replace(/[^A-Za-z0-9]/g, '');

                    if (onlyNumeric) {
                        transponder = t.replace(/[^0-9]/g, '');
                    }

                    if (transponder.length <= 15) {
                        setCurrentValue(transponder);
                    }

                    if (isTransponderValid(transponder)) {
                        onValid(transponder);
                        setValid(true);
                    } else {
                        onInvalid(transponder);
                        setValid(false);
                    }

                },
                [setCurrentValue, setValid, onValid, onInvalid, onlyNumeric]
            )}
            disabled={disabled}
            value={currentValue}
        />

    </div>

});

export default TransponderControl;


