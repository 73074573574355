import React, {useEffect, useMemo, useState} from "react";
import FormDataComponent from "./FormDataComponent";
import Button from "react-bootstrap/Button";
import locale from "app/AnimalLost/locales/locales";
import classnames from "classnames";
import useTranslations from "system/Translations/UseTranslations";
import GenericFormError from "modules/Form/Errors/FormGenericError";
import Loading from "modules/Loading/Loading";
import "app/AnimalLost/assets/lost.scss";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalButtons from "modules/Modal/Buttons/ModalButtons";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {Link} from "react-router-dom";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import {Trans} from "react-i18next";

const FormComponent = (props) => {

    const {errors = {}, loading, onSubmit, success, animal, readOnly, genericError, type} = props;
    const {t} = useTranslations("animal", locale);

    const [lostInfo, setLostInfo] = useState(animal ? animal : {
        eventCountry: "PT",
        status: type,
    });

    useEffect(
        () => {
            if (!animal)
                setLostInfo({
                    eventCountry: "PT",
                    status: type,
                })
        },
        [type, animal]
    );

    const buttons = useMemo(
        () => <>
            {!readOnly &&
            <div className={"buttons"}>
                <Button
                    variant="primary"
                    onClick={() => onSubmit(lostInfo)}>
                    {t("confirm")}
                </Button>
            </div>
            }
        </>,
        [onSubmit, readOnly, t, lostInfo]
    );


    return <div className={classnames("form-lost", {"view-mode": readOnly})}>

        <GenericFormError t={t} errors={errors} genericError={genericError}/>


        {success &&
        <Modal
            centered
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            show={true}>

            <Modal.Body>
                <div className={"success-message"}>

                    <div className={"icon-container"}>
                        <FontAwesomeIcon icon={faCheck} className={"icon"}/>
                    </div>

                    <div className={"main-text"}>
                        {t(type === AnimalStatus.LOST ? 'success_lost_message_title' : 'success_found_message_title')}
                    </div>
                    <Trans>
                        {t(type === AnimalStatus.LOST ? 'success_lost_message' : 'success_found_message')}
                    </Trans>
                </div>
            </Modal.Body>

            <ModalButtons>
                <Link to={"/"}>
                    <Button variant={"success"}>
                        {t('close')}
                    </Button>
                </Link>
            </ModalButtons>
        </Modal>

        }

        <Loading visible={loading}/>

        {buttons}

        {lostInfo?.status &&
        <FormDataComponent
            errors={errors}
            readOnly={readOnly}
            setLostInfo={setLostInfo}
            lostInfo={lostInfo}
        />
        }
        {buttons}
    </div>
}

export default FormComponent;
