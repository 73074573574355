import {useEffect, useRef, useState} from "react";
import useOnScreen from "system/Window/useOnScreen";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "modules/Form/Group/FormGroup";
import EnumDropdown from "modules/Form/Dropdowns/EnumDropdown/EnumDropdown";
import Form from "react-bootstrap/Form";
import {AnimalStatus} from "app/AnimalLost/utils/types";
import {getOrNull} from "system/Objects/ObjectParameters";
import SpecieDropdown from "modules/Form/Dropdowns/ClassDropdown/components/SpecieDropdown";
import BreedDropdown from "modules/Form/Dropdowns/ClassDropdown/components/BreedDropdown";
import FormGeofence from "modules/Form/Geofence/GeofenceForm";
import DatePicker from "modules/Form/DatePicker/DatePicker";
import {parseNumber} from "system/Utils/parseNumber";

const ListFilterComponent = props => {

    const {searchFilter, t} = props;
    const [filterState, setFilterState] = useState();

    const filterRef = useRef(null);
    const isVisible = useOnScreen(filterRef);

    useEffect(() => {
        setFilterState({
            ...searchFilter
        });
    }, [searchFilter]);

    return <div ref={filterRef}>
        {filterState &&
        <>
            <Row>
                <Col sm={12} lg={12}>
                    <FormGroup>
                        <Form.Label>{t('status')}</Form.Label>
                        <EnumDropdown
                            t={t}
                            placeholder={t('status_ph')}
                            id={"status"}
                            isMulti={false}
                            options={AnimalStatus}
                            value={getOrNull(filterState.status)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    status: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>


            </Row>

            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup filter-hidden={filterState?.breedIds}>
                        <Form.Label>{t('specie')}</Form.Label>
                        <SpecieDropdown
                            id={"specieIds"}
                            isMulti={true}
                            value={getOrNull(filterState?.specieIds)}
                            onChange={(specieId) => setFilterState(filterState => ({
                                ...filterState,
                                specieIds: specieId,
                                breedIds: null,
                                categoryIds: null,
                            }))}

                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('breed')}</Form.Label>
                        <BreedDropdown
                            id={"breedIds"}
                            isMulti={true}
                            specieIds={filterState?.specieIds}
                            isDisabled={!filterState?.specieIds}
                            value={getOrNull(filterState?.breedIds)}
                            onChange={(specieId) => setFilterState(filterState => ({
                                ...filterState,
                                breedIds: specieId,
                            }))}

                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col sm={12} lg={12}>
                    <FormGeofence
                        t={t}
                        visible={isVisible}
                        ignoreSubmit={false}
                        entry={{
                            districts: getOrNull(filterState.eventDistricts),
                            counties: getOrNull(filterState.eventCounties),
                            parishes: getOrNull(filterState.eventParishes),
                        }}
                        onChange={(val) => setFilterState(filterState => ({
                            ...filterState,
                            eventDistricts: val?.districts,
                            eventCounties: val?.counties,
                            eventParishes: val?.parishes,

                        }))}
                    />
                </Col>
            </Row>

            <Row>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('from_creation_date')}</Form.Label>
                        <DatePicker
                            name={"fromCreationTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.fromCreationTimestamp, undefined)}
                            toDate={parseNumber(filterState.toCreationTimestamp, undefined)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    fromCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('to_creation_date')}</Form.Label>
                        <DatePicker
                            name={"toCreationTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.toCreationTimestamp, undefined)}
                            fromDate={parseNumber(filterState.fromCreationTimestamp, undefined)}
                            outputEndOfDayTimestamp={true}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    toCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

            </Row>

            <Row>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('from_occurrence_date')}</Form.Label>
                        <DatePicker
                            name={"fromEventTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.fromEventTimestamp, undefined)}
                            toDate={parseNumber(filterState.toEventTimestamp, undefined)}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    fromCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>

                <Col sm={12} lg={6}>
                    <FormGroup>
                        <Form.Label>{t('to_occurrence_date')}</Form.Label>
                        <DatePicker
                            name={"toEventTimestamp"}
                            placeholder={t("date_ph")}
                            value={parseNumber(filterState.toEventTimestamp, undefined)}
                            fromDate={parseNumber(filterState.fromEventTimestamp, undefined)}
                            outputEndOfDayTimestamp={true}
                            onChange={(val) => {
                                setFilterState({
                                    ...filterState,
                                    toCreationTimestamp: val,
                                })
                            }}
                        />
                    </FormGroup>
                </Col>


            </Row>

        </>
        }

    </div>

}

export default ListFilterComponent;
